<template>
  <section class="bg-promy-gray-50 py-16 sm:pb-0">
    <div class="w-full font-poppins text-center sm:text-left">
      <h2
        class="text-dark-grey-400 sm:w-2/3 m-auto font-extrabold text-5xl sm:text-xl sm:text-left mb-2"
      >
        Ils en parlent mieux que nous...
      </h2>
      <p class="w-1/5 sm:w-2/3 m-auto text-base text-dark-grey-400">
        Vous êtes au bon endroit pour vendre votre terrain dans les meilleures
        conditions !
      </p>
    </div>
    <div class="mt-16 mb-24 sm:mt-6 m-auto">
      <splide class="md:hidden" :options="options_lg">
        <splide-slide>
          <div
            class="bg-white min-h-76 lg:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_1.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Gaspard RIOT
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Décembre 2020</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              Je recommande cet outil. J’avais besoin d’informations sur ma
              propriété, j’ai pu les avoir instantanément et j’ai été rappelé
              très rapidement pour étudier mon projet de vente. C’est une équipe
              à l’écoute et ça joue beaucoup. Merci.
            </p>
          </div>
        </splide-slide>
        <splide-slide>
          <div
            class="bg-white min-h-76 lg:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_2.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Jeanne ETIENNE
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Février 2021</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              J’étais en train de me faire démarcher par de nombreux agents
              immos et promoteurs... mais j’ai décidé de faire confiance à ce
              site car ils ont un panel de comparaison beaucoup plus large. De
              plus, ils ont fait une vraie étude de mon terrain gratuitement!
              C’est top et rapide!
            </p>
          </div>
        </splide-slide>
        <splide-slide>
          <div
            class="bg-white min-h-76 lg:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_3.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Sandrine VEYRIT
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Septembre 2020</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              A la suite d’un héritage familial, nous souhaitions vendre notre
              propriété. Difficile de savoir vers qui nous tourner... Un ami
              nous a parlé de Promy.fr, c’est génial. Une vente
              simplifiée avec des informations claires et, un accompagnement de
              qualité!
            </p>
          </div>
        </splide-slide>
      </splide>

      <splide class="hidden md:block" :options="options_md">
        <splide-slide>
          <div
            class="bg-white sm:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_1.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Gaspard RIOT
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Décembre 2020</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              Je recommande cet outil. J’avais besoin d’informations sur ma
              propriété, j’ai pu les avoir instantanément et j’ai été rappelé
              très rapidement pour étudier mon projet de vente. C’est une équipe
              à l’écoute et ça joue beaucoup. Merci.
            </p>
          </div>
        </splide-slide>
        <splide-slide>
          <div
            class="bg-white sm:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_2.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Jeanne ETIENNE
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Février 2021</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              J’étais en train de me faire démarcher par de nombreux agents
              immos et promoteurs... mais j’ai décidé de faire confiance à ce
              site car ils ont un panel de comparaison beaucoup plus large. De
              plus, ils ont fait une vraie étude de mon terrain gratuitement!
              C’est top et rapide!
            </p>
          </div>
        </splide-slide>
        <splide-slide>
          <div
            class="bg-white sm:min-h-82 px-10 pt-8 pb-12 rounded-lg font-poppins text-base font-medium text-promy-gray-500 space-y-4 shadow-lg"
          >
            <div class="w-full flex items-center">
              <img
                src="/images/pic_3.svg"
                class="w-20 sm:w-16 h-20 sm:h-16 rounded-full mr-4"
              />
              <div class="w-10/12 flex justify-between">
                <div>
                  <p class="font-extrabold text-dark-purple-400">
                    Sandrine VEYRIT
                  </p>
                  <p class="text-dark-grey-400">Particulier</p>
                </div>
                <p class="text-dark-grey-400">Septembre 2020</p>
              </div>
            </div>
            <p class="text-dark-grey-400">
              A la suite d’un héritage familial, nous souhaitions vendre notre
              propriété. Difficile de savoir vers qui nous tourner... Un ami
              nous a parlé de Promy.fr, c’est génial. Une vente
              simplifiée avec des informations claires et, un accompagnement de
              qualité!
            </p>
          </div>
        </splide-slide>
      </splide>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      options_lg: {
        type: 'loop',
        rewind: true,
        gap: '1rem',
        autoplay: true,
        navigation: false,
        focus: 'center',
        padding: {
          right: '33%',
          left: '33%',
        },
      },
      options_md: {
        type: 'loop',
        rewind: true,
        gap: '1rem',
        autoplay: true,
        navigation: false,
        focus: 'center',
        padding: {
          right: '5%',
          left: '5%',
        },
      },
    }
  },
}
</script>

<style lang="scss">
.splide {
  position: relative;
  margin: auto;
  max-width: 100% !important;
  @apply pb-12;
  .splide__track {
    @apply py-4;
  }
  li {
    @apply opacity-50;
  }
  .is-visible {
    @apply opacity-100;
  }
  .splide__arrows {
    position: absolute;
    width: 150px;
    bottom: 20px;
    right: 32.3%;
    @screen md {
      right: 3%;
    }
    .splide__arrow {
      @apply w-12 h-12 flex justify-center items-center rounded-lg bg-white;
      svg {
        width: 20px;
        height: 20px;
        fill: black;
      }
    }
  }
  .splide__pagination {
    width: fit-content;
    @screen sm {
      left: 20%;
    }
  }
  .splide__pagination__page.is-active {
    @apply bg-blue-ice-400;
  }

  .splide__pagination__page:hover {
    background: #ccc;
  }
}
</style>
