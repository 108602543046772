import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import './plugins'
import './filters'
import './utilities'
import _ from 'lodash'

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app')
