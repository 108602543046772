<template>
  <LayoutIcon :fill="fill">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3118 16.522H29.4914V0.677966C29.4914 0.303559 29.1879 0 28.8135 0H5.68804C5.31363 0 5.01007 0.303559 5.01007 0.677966V39.322C5.01007 39.6964 5.31363 40 5.68804 40H34.3118C34.6862 40 34.9897 39.6964 34.9897 39.322V17.2C34.9897 16.8256 34.6862 16.522 34.3118 16.522ZM9.97956 17.2V38.6441H6.366V1.35593H28.1355V16.522H10.6575C10.2831 16.522 9.97956 16.8256 9.97956 17.2ZM21.8033 38.6441H15.7016V32.3864H21.8033V38.6441ZM29.2609 38.6441H23.1592V32.3864H29.2609V38.6441ZM30.5965 38.6441V31.7085C30.5965 31.3341 30.293 31.0305 29.9185 31.0305H15.0507C14.6763 31.0305 14.3728 31.3341 14.3728 31.7085V38.6441H11.3355V17.878H33.6338L33.6541 38.6441H30.5965Z"
      />
      <path
        d="M24.1696 2.86084H20.3119C19.9375 2.86084 19.634 3.1644 19.634 3.53881V7.38965C19.634 7.76406 19.9375 8.06762 20.3119 8.06762H24.1628C24.5372 8.06762 24.8408 7.76406 24.8408 7.38965V3.53881C24.8408 3.16703 24.5413 2.86457 24.1696 2.86084ZM20.9899 6.71168V4.21677H23.4848L23.4916 6.71168H20.9899Z"
      />
      <path
        d="M13.9933 2.86084H10.1424C9.76804 2.86084 9.46448 3.1644 9.46448 3.53881V7.38965C9.46448 7.76406 9.76804 8.06762 10.1424 8.06762H13.9933C14.3677 8.06762 14.6712 7.76406 14.6712 7.38965V3.53881C14.6712 3.1644 14.3677 2.86084 13.9933 2.86084ZM13.3153 6.71168H10.8204V4.21677H13.3153V6.71168Z"
      />
      <path
        d="M24.1696 9.83057H20.3119C19.9375 9.83057 19.634 10.1341 19.634 10.5085V14.3729C19.634 14.7473 19.9375 15.0509 20.3119 15.0509H24.1628C24.5372 15.0509 24.8408 14.7473 24.8408 14.3729V10.5085C24.8408 10.1368 24.5413 9.8343 24.1696 9.83057ZM20.9899 13.6814V11.1865H23.4848L23.4916 13.6814H20.9899Z"
      />
      <path
        d="M13.9933 9.83057H10.1424C9.76804 9.83057 9.46448 10.1341 9.46448 10.5085V14.3729C9.46448 14.7473 9.76804 15.0509 10.1424 15.0509H13.9933C14.3677 15.0509 14.6712 14.7473 14.6712 14.3729V10.5085C14.6712 10.1341 14.3677 9.83057 13.9933 9.83057ZM13.3153 13.6814H10.8204V11.1865H13.3153V13.6814Z"
      />
      <path
        d="M29.4915 18.8135H25.6406C25.2662 18.8135 24.9626 19.117 24.9626 19.4914V23.3423C24.9626 23.7167 25.2662 24.0203 25.6406 24.0203H29.4915C29.8659 24.0203 30.1694 23.7167 30.1694 23.3423V19.4914C30.1694 19.117 29.8659 18.8135 29.4915 18.8135ZM28.8135 22.6643H26.3186V20.1694H28.8135V22.6643Z"
      />
      <path
        d="M19.322 18.8135H15.4711C15.0967 18.8135 14.7932 19.117 14.7932 19.4914V23.3423C14.7932 23.7167 15.0967 24.0203 15.4711 24.0203H19.322C19.6964 24.0203 19.9999 23.7167 19.9999 23.3423V19.4914C19.9999 19.117 19.6964 18.8135 19.322 18.8135ZM18.644 22.6643H16.1491V20.1694H18.644V22.6643Z"
      />
      <path
        d="M29.4915 25.0713H25.6406C25.2662 25.0713 24.9626 25.3748 24.9626 25.7493V29.6001C24.9626 29.9745 25.2662 30.2781 25.6406 30.2781H29.4915C29.8659 30.2781 30.1694 29.9745 30.1694 29.6001V25.7493C30.1694 25.3748 29.8659 25.0713 29.4915 25.0713ZM28.8135 28.9221H26.3186V26.4272H28.8135V28.9221Z"
      />
      <path
        d="M19.322 25.0713H15.4711C15.0967 25.0713 14.7932 25.3748 14.7932 25.7493V29.6001C14.7932 29.9745 15.0967 30.2781 15.4711 30.2781H19.322C19.6964 30.2781 19.9999 29.9745 19.9999 29.6001V25.7493C19.9999 25.3748 19.6964 25.0713 19.322 25.0713ZM18.644 28.9221H16.1491V26.4272H18.644V28.9221Z"
      />
      <rect x="26" y="26" width="3" height="3" />
    </svg>
  </LayoutIcon>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'orange',
    },
  },
}
</script>
