var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutTunnel',[_c('template',{slot:"left-side"},[_c('div',{staticClass:"flex flex-col flex-1 items-start self-center justify-around px-16 pb-8 lg:px-8 max-w-xl xl:w-144"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"flex flex-col space-y-6 w-full"},[_c('div',{staticClass:"text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl mb-5 sm:leading-7 leading-9"},[_vm._v(" Vous avez une question ? Vous souhaitez obtenir un renseignement ? Aucun problème, envoyez-nous un message. Nous vous répondons rapidement. ")]),_c('div',[_c('TextInput',{attrs:{"label":"Prénom","placeholder":"Prénom","rules":"required","value":_vm.form.prenom},on:{"input":function (val) {
                  _vm.form.prenom = val
                }}})],1),_c('div',[_c('TextInput',{attrs:{"label":"Nom","placeholder":"Nom","rules":"required","value":_vm.form.nom},on:{"input":function (val) {
                  _vm.form.nom = val
                }}})],1),_c('div',[_c('TextInput',{attrs:{"label":"Email","placeholder":"Email","rules":"required|email","value":_vm.form.email},on:{"input":function (val) {
                  _vm.form.email = val
                }}})],1),_c('div',[_c('ProTextarea',{attrs:{"label":"Message","placeholder":"Description","rules":"required","value":_vm.form.message,"type":"number"},on:{"input":function (val) {
                  _vm.form.message = val
                }},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", _vm._n($$v))},expression:"form.message"}})],1),_c('div',[_c('PromyButton',{staticClass:"sm:text-xs",attrs:{"btnText":"Envoyer"},on:{"click":function($event){return _vm.sendContact(invalid)}}})],1)])]}}])})],1)]),_c('template',{slot:"right-side"},[_c('div',{staticClass:"bg-center bg-cover bg-no-repeat lg:hidden",staticStyle:{"background-image":"url(/images/img-header.jpg)"}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }