import Vue from 'vue'
import VueSplide from '@splidejs/vue-splide'

Vue.use(VueSplide)

// import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// or
// import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
// or
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
