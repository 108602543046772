<template>
  <div id="app" class="flex flex-col min-h-screen">
    <Navbar class="z-50" />
    <div style="padding-top: 80px" class="flex flex-col flex-1">
      <router-view />
    </div>
    <BackToTop class="hidden lg:flex invisible"></BackToTop>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style src="./assets/styles/tailwind.css"></style>
