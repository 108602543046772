<template>
  <div>
    <label
      v-if="label"
      class="mb-2 text-promy-gray-600 font-dosis font-extrabold"
    >
      {{ label }}
    </label>
    <div class="flex flex-1">
      <select
        v-if="hasPrefix"
        v-model="prefix"
        @change="handleInput(value)"
        class="bg-white prefix rounded-md text-promy-gray-700 border-promy-gray-500"
      >
        <option
          v-for="(prefixOption, index) in prefixOptions"
          :value="prefixOption"
          :key="index"
        >
          {{ prefixOption }}
        </option>
      </select>
      <validation-provider
        class="flex flex-col w-full"
        ref="provider"
        :rules="rules"
        v-slot="{ errors }"
      >
        <input
          :placeholder="placeholder"
          :type="type"
          :class="[
            unit ? 'pr-10' : 'pr-3',
            errors[0] ? 'border-promy-red-400' : '',
            hasPrefix ? 'with-prefix' : '',
          ]"
          class="relative w-full flex w-full py-2 pl-3 text-left bg-white border rounded-md shadow-sm outline-none appearance-none cursor-default text-promy-gray-700 border-promy-gray-500 focus:outline-none focus:ring-1 sm:text-sm"
          :value="value"
          @input="handleInput($event.target.value)"
        />
        <template v-if="unit">
          <span class="absolute right-3 top-2 text-promy-gray-800">{{
            unit
          }}</span>
        </template>
        <div
          v-show="errors[0]"
          style="margin-left: -60px"
          class="mt-1 text-xs text-promy-red-400"
        >
          {{ errors[0] }}
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    unit: {
      type: [String, Number],
      default: null,
    },
    hasPrefix: {
      type: Boolean,
      default: false,
    },
    prefixOptions: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      prefix: this.prefixOptions[0],
    }
  },
  methods: {
    handleInput(value) {
      if (this.hasPrefix) {
        this.$emit('input', { prefix: this.prefix, value })
      } else {
        this.$emit('input', value)
      }
    },
  },
}
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.prefix {
  border-width: 1px 1px 1px 1px;
  border-radius: 6px 0 0 6px;
  height: 43px;
}

.with-prefix {
  border-width: 1px 1px 1px 0 !important;
  border-radius: 0 6px 6px 0 !important;
}
</style>
