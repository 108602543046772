const getDefaultState = () => {
  return {
    map: null,
  }
}
export default {
  namespaced: true,
  state: getDefaultState,
  getters: {
    map(state) {
      return state.map
    },
  },
  mutations: {
    MAP(state, payload) {
      state.map = payload
    },
  },
}
