<template>
  <div :class="[$style.baseIcom, $style[fill]]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
    },
  },
}
</script>

<style lang="scss" module>
.baseIcom {
  path,
  rect {
    fill: var(--icon-fill-color);
  }
  svg {
    @screen sm {
      width: 25px;
      height: 25px;
    }
  }
}

.orange {
  --icon-fill-color: theme('colors.promy-orange.300');
}
.purple {
  --icon-fill-color: theme('colors.promy-purple.300');
}
.blue-ice {
  --icon-fill-color: theme('colors.blue-ice.400');
}
.white {
  --icon-fill-color: theme('colors.white');
}
</style>
