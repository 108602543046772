<template>
  <LayoutIcon :fill="fill">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3333 38.6667H35.3333V16.6667C35.3333 16.2985 35.0348 16 34.6667 16H25.3333V0.666667C25.3333 0.2985 25.0348 0 24.6667 0H7.33333C6.96517 0 6.66667 0.2985 6.66667 0.666667V38.6667H0.666667C0.2985 38.6667 0 38.9652 0 39.3333C0 39.7015 0.2985 40 0.666667 40H39.3333C39.7015 40 40 39.7015 40 39.3333C40 38.9652 39.7015 38.6667 39.3333 38.6667ZM14 38.6667H11.3333V32.6667C11.3333 32.2985 11.6318 32 12 32H13.3333C13.7015 32 14 32.2985 14 32.6667V38.6667ZM16.6667 16.6667V38.6667H15.3333V32.6667C15.3333 31.5621 14.4379 30.6667 13.3333 30.6667H12C10.8954 30.6667 10 31.5621 10 32.6667V38.6667H8V1.33333H24V16H17.3333C16.9652 16 16.6667 16.2985 16.6667 16.6667ZM34 38.6667H18V17.3333H34V38.6667Z"
      />
      <path
        d="M21.3333 9.3335H17.3333C16.9651 9.3335 16.6666 9.632 16.6666 10.0002V14.0002C16.6666 14.3683 16.9651 14.6668 17.3333 14.6668H21.3333C21.7015 14.6668 22 14.3683 22 14.0002V10.0002C22 9.632 21.7015 9.3335 21.3333 9.3335ZM20.6666 13.3335H18V10.6668H20.6666V13.3335Z"
      />
      <path
        d="M21.3333 2.6665H17.3333C16.9651 2.6665 16.6666 2.965 16.6666 3.33317V7.33317C16.6666 7.70134 16.9651 7.99984 17.3333 7.99984H21.3333C21.7015 7.99984 22 7.70134 22 7.33317V3.33317C22 2.965 21.7015 2.6665 21.3333 2.6665ZM20.6666 6.6665H18V3.99984H20.6666V6.6665Z"
      />
      <path
        d="M14.6667 9.3335H10.6667C10.2985 9.3335 10 9.632 10 10.0002V14.0002C10 14.3683 10.2985 14.6668 10.6667 14.6668H14.6667C15.0348 14.6668 15.3333 14.3683 15.3333 14.0002V10.0002C15.3333 9.632 15.0348 9.3335 14.6667 9.3335ZM14 13.3335H11.3333V10.6668H14V13.3335Z"
      />
      <path
        d="M14.6667 2.6665H10.6667C10.2985 2.6665 10 2.965 10 3.33317V7.33317C10 7.70134 10.2985 7.99984 10.6667 7.99984H14.6667C15.0348 7.99984 15.3333 7.70134 15.3333 7.33317V3.33317C15.3333 2.965 15.0348 2.6665 14.6667 2.6665ZM14 6.6665H11.3333V3.99984H14V6.6665Z"
      />
      <path
        d="M14.6667 16H10.6667C10.2985 16 10 16.2985 10 16.6667V20.6667C10 21.0348 10.2985 21.3333 10.6667 21.3333H14.6667C15.0348 21.3333 15.3333 21.0348 15.3333 20.6667V16.6667C15.3333 16.2985 15.0348 16 14.6667 16ZM14 20H11.3333V17.3333H14V20Z"
      />
      <path
        d="M14.6667 22.6665H10.6667C10.2985 22.6665 10 22.965 10 23.3332V27.3332C10 27.7013 10.2985 27.9998 10.6667 27.9998H14.6667C15.0348 27.9998 15.3333 27.7013 15.3333 27.3332V23.3332C15.3333 22.965 15.0348 22.6665 14.6667 22.6665ZM14 26.6665H11.3333V23.9998H14V26.6665Z"
      />
      <path
        d="M27.3333 30.6668H31.3333C31.7015 30.6668 32 30.3683 32 30.0002V26.0002C32 25.632 31.7015 25.3335 31.3333 25.3335H27.3333C26.9651 25.3335 26.6666 25.632 26.6666 26.0002V30.0002C26.6666 30.3683 26.9651 30.6668 27.3333 30.6668ZM28 26.6668H30.6666V29.3335H28V26.6668Z"
      />
      <path
        d="M27.3333 23.9998H31.3333C31.7015 23.9998 32 23.7013 32 23.3332V19.3332C32 18.965 31.7015 18.6665 31.3333 18.6665H27.3333C26.9651 18.6665 26.6666 18.965 26.6666 19.3332V23.3332C26.6666 23.7013 26.9651 23.9998 27.3333 23.9998ZM28 19.9998H30.6666V22.6665H28V19.9998Z"
      />
      <path
        d="M27.3333 37.3333H31.3333C31.7015 37.3333 32 37.0348 32 36.6667V32.6667C32 32.2985 31.7015 32 31.3333 32H27.3333C26.9651 32 26.6666 32.2985 26.6666 32.6667V36.6667C26.6666 37.0348 26.9651 37.3333 27.3333 37.3333ZM28 33.3333H30.6666V36H28V33.3333Z"
      />
      <path
        d="M20.6667 30.6668H24.6667C25.0348 30.6668 25.3333 30.3683 25.3333 30.0002V26.0002C25.3333 25.632 25.0348 25.3335 24.6667 25.3335H20.6667C20.2985 25.3335 20 25.632 20 26.0002V30.0002C20 30.3683 20.2985 30.6668 20.6667 30.6668ZM21.3333 26.6668H24V29.3335H21.3333V26.6668Z"
      />
      <path
        d="M20.6667 23.9998H24.6667C25.0348 23.9998 25.3333 23.7013 25.3333 23.3332V19.3332C25.3333 18.965 25.0348 18.6665 24.6667 18.6665H20.6667C20.2985 18.6665 20 18.965 20 19.3332V23.3332C20 23.7013 20.2985 23.9998 20.6667 23.9998ZM21.3333 19.9998H24V22.6665H21.3333V19.9998Z"
      />
      <path
        d="M20.6667 37.3333H24.6667C25.0348 37.3333 25.3333 37.0348 25.3333 36.6667V32.6667C25.3333 32.2985 25.0348 32 24.6667 32H20.6667C20.2985 32 20 32.2985 20 32.6667V36.6667C20 37.0348 20.2985 37.3333 20.6667 37.3333ZM21.3333 33.3333H24V36H21.3333V33.3333Z"
      />
    </svg>
  </LayoutIcon>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'orange',
    },
  },
}
</script>
