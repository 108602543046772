<template>
  <validation-provider class="flex flex-col" :rules="rules" v-slot="{ errors }">
    <label v-if="label" class="mb-2 text-dark-grey-400 font-poppins font-medium"
      >{{ label }}
    </label>
    <div class="relative">
      <input
        :placeholder="placeholder"
        :type="type"
        :class="[
          unit ? 'pr-10' : 'pr-3',
          errors[0] ? 'border-promy-red-400' : '',
        ]"
        class="relative flex flex-1 w-full py-2 pl-3 text-left bg-white border rounded-md shadow-sm outline-none appearance-none cursor-default text-promy-gray-700 border-promy-gray-500 focus:outline-none focus:ring-1 sm:text-sm"
        :value="value"
        @input="handleInput($event.target.value)"
      />
      <template v-if="unit">
        <span class="absolute right-3 top-2 text-promy-gray-800">{{
          unit
        }}</span>
      </template>
    </div>
    <span v-show="errors[0]" class="mt-1 text-xs text-promy-red-400">{{
      errors[0]
    }}</span>
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
    },
    value: {
      required: true,
    },
    placeholder: {
      required: true,
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    unit: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
