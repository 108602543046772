<template>
  <validation-provider :rules="rules" v-slot="{ errors }">
    <div class="w-full flex flex-col">
      <label
        :for="uniqueId"
        class="mb-2 text-dark-grey-400 font-poppins font-medium"
      >
        {{ label }}
      </label>
      <textarea
        :class="[errors[0] ? 'border-red-600' : 'border-promy-gray-500']"
        class="w-full py-2 pl-3 text-left bg-white border rounded-md shadow-sm outline-none appearance-none cursor-default text-promy-gray-700 focus:outline-none focus:ring-1 sm:text-sm"
        :id="uniqueId"
        :value="value"
        @input="handleInput($event.target.value)"
        :rows="row"
        :placeholder="placeholder"
        name="description"
      />
    </div>
    <span class="mt-1 text-xs text-promy-red-400" v-if="errors[0]">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
    },
    rules: {
      type: String,
    },
    row: {
      type: String,
      default: '5',
    },
    classTextArea: {
      type: String,
      default: 'w-full',
    },
    placeholder: {
      type: String,
      default: 'Description',
    },
  },
  computed: {
    uniqueId() {
      return `textarea-${this._uid}`
    },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
