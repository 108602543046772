<template>
  <svg
    width="40"
    height="37"
    viewBox="0 0 40 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="[$style.baseIcom, $style[fill]]"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M31.1982 20.9984L38.9988 32.4978L24.8808 35.1449C23.6461 35.3764 22.3732 35.2982 21.1761 34.9173L1.00122 28.498V23.4983L8.30191 22.4984L14.0004 19.9985"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.9996 2.8125C18.3417 2.8125 14.5626 6.40935 14.5626 10.8393C14.5626 15.9367 20.1873 24.0145 22.2526 26.8045C22.3383 26.9223 22.4507 27.0182 22.5805 27.0843C22.7103 27.1503 22.8539 27.1848 22.9996 27.1848C23.1453 27.1848 23.2889 27.1503 23.4187 27.0843C23.5485 27.0182 23.6609 26.9223 23.7466 26.8045C25.8119 24.0156 31.4365 15.9408 31.4365 10.8393C31.4365 6.40935 27.6575 2.8125 22.9996 2.8125Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.9998 14.0617C24.553 14.0617 25.8122 12.8025 25.8122 11.2494C25.8122 9.69613 24.553 8.43701 22.9998 8.43701C21.4466 8.43701 20.1875 9.69613 20.1875 11.2494C20.1875 12.8025 21.4466 14.0617 22.9998 14.0617Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="40" height="36.2813" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'orange',
    },
  },
}
</script>

<style lang="scss" module>
.baseIcom {
  path {
    stroke: var(--icon-fill-color);
  }
  svg {
    @screen sm {
      width: 30px;
      height: 30px;
    }
  }
}

.orange {
  --icon-fill-color: theme('colors.promy-orange.300');
}
.green {
  --icon-fill-color: theme('colors.promy-green.400');
}
.blue-ice {
  --icon-fill-color: theme('colors.blue-ice.400');
}
</style>
