<template>
  <LayoutIcon :fill="fill">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.1919 19.558L20.4419 0.807992C20.3247 0.690822 20.1657 0.625 20 0.625C19.8343 0.625 19.6753 0.690822 19.5581 0.807992L0.808114 19.558C0.720733 19.6454 0.661229 19.7568 0.637124 19.878C0.61302 19.9992 0.625396 20.1248 0.67269 20.239C0.719984 20.3532 0.800071 20.4508 0.902827 20.5195C1.00558 20.5882 1.12639 20.6248 1.24999 20.6249H4.37499V35.6249H3.74999C3.58423 35.6249 3.42526 35.6907 3.30805 35.8079C3.19084 35.9251 3.12499 36.0841 3.12499 36.2499V38.7499C3.12499 38.9156 3.19084 39.0746 3.30805 39.1918C3.42526 39.309 3.58423 39.3749 3.74999 39.3749H36.25C36.4157 39.3749 36.5747 39.309 36.6919 39.1918C36.8091 39.0746 36.875 38.9156 36.875 38.7499V36.2499C36.875 36.0841 36.8091 35.9251 36.6919 35.8079C36.5747 35.6907 36.4157 35.6249 36.25 35.6249H35.625V20.6249H38.75C38.8736 20.6248 38.9944 20.5882 39.0971 20.5195C39.1999 20.4508 39.28 20.3532 39.3273 20.239C39.3746 20.1248 39.387 19.9992 39.3629 19.878C39.3387 19.7568 39.2792 19.6454 39.1919 19.558ZM35.625 38.1249H4.37499V36.8749H35.625V38.1249ZM16.875 35.6249H9.37499V24.3749H16.875V35.6249ZM18.125 35.6249V23.7499C18.125 23.5841 18.0591 23.4251 17.9419 23.3079C17.8247 23.1907 17.6657 23.1249 17.5 23.1249H8.74999C8.58423 23.1249 8.42526 23.1907 8.30805 23.3079C8.19084 23.4251 8.12499 23.5841 8.12499 23.7499V35.6249H5.62499V20.2586L20 5.88362L34.375 20.2586V35.6249H18.125ZM35.2587 19.3749L20.4419 4.55799C20.3247 4.44082 20.1657 4.375 20 4.375C19.8343 4.375 19.6753 4.44082 19.5581 4.55799L4.74124 19.3749H2.75874L20 2.13362L37.2412 19.3749H35.2587Z"
      />
      <path
        d="M24.375 16.25C24.375 15.3847 24.1184 14.5388 23.6377 13.8194C23.1569 13.0999 22.4737 12.5392 21.6742 12.208C20.8748 11.8769 19.9951 11.7903 19.1465 11.9591C18.2978 12.1279 17.5183 12.5446 16.9064 13.1564C16.2946 13.7683 15.8779 14.5478 15.7091 15.3965C15.5403 16.2451 15.6269 17.1248 15.958 17.9242C16.2892 18.7237 16.8499 19.407 17.5694 19.8877C18.2888 20.3684 19.1347 20.625 20 20.625C21.1599 20.6237 22.2719 20.1623 23.0921 19.3421C23.9123 18.5219 24.3737 17.4099 24.375 16.25ZM16.875 16.25C16.875 15.6319 17.0583 15.0277 17.4017 14.5138C17.745 13.9999 18.2331 13.5994 18.8041 13.3629C19.3751 13.1264 20.0035 13.0645 20.6097 13.185C21.2159 13.3056 21.7727 13.6033 22.2097 14.0403C22.6467 14.4773 22.9444 15.0342 23.065 15.6403C23.1855 16.2465 23.1236 16.8749 22.8871 17.4459C22.6506 18.0169 22.2501 18.505 21.7362 18.8483C21.2223 19.1917 20.6181 19.375 20 19.375C19.1715 19.374 18.3772 19.0445 17.7914 18.4586C17.2056 17.8728 16.876 17.0785 16.875 16.25Z"
      />
      <path
        d="M32.5 23.125H20C19.8342 23.125 19.6753 23.1908 19.5581 23.3081C19.4408 23.4253 19.375 23.5842 19.375 23.75V32.5C19.375 32.6658 19.4408 32.8247 19.5581 32.9419C19.6753 33.0592 19.8342 33.125 20 33.125H32.5C32.6658 33.125 32.8247 33.0592 32.9419 32.9419C33.0592 32.8247 33.125 32.6658 33.125 32.5V23.75C33.125 23.5842 33.0592 23.4253 32.9419 23.3081C32.8247 23.1908 32.6658 23.125 32.5 23.125ZM31.875 27.5H26.875V24.375H31.875V27.5ZM25.625 24.375V27.5H20.625V24.375H25.625ZM20.625 28.75H25.625V31.875H20.625V28.75ZM26.875 31.875V28.75H31.875V31.875H26.875Z"
      />
      <path d="M11.875 28.125H10.625V30.625H11.875V28.125Z" />
    </svg>
  </LayoutIcon>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'orange',
    },
  },
}
</script>
