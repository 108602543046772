<template>
  <div>
    <div class="relative mt-1">
      <button
        :id="`#select-${_uid}`"
        @click="show = !show"
        type="button"
        class="relative w-full py-2 pr-10 text-left bg-white border rounded-md shadow-sm outline-none cursor-default border-promy-gray-500 focus:outline-none focus:ring-1 sm:text-sm"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <span class="flex items-center">
          <span
            class="block ml-3 capitalize font-poppins truncate text-promy-gray-700"
          >
            {{ value }}
          </span>
        </span>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none"
        >
          <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 5.6665L0.669872 0.666505L9.33013 0.666504L5 5.6665Z"
              fill="#262544"
            />
          </svg>
        </span>
      </button>
      <ul
        v-click-outside="away"
        v-if="show"
        class="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
      >
        <li
          @click="choosedSelected(selection)"
          v-for="(selection, index) in selections"
          :key="index"
          class="relative py-2 pl-3 transition-colors duration-150 cursor-pointer select-none text-promy-gray-700 pr-9 hover:bg-promy-gray-100"
          :id="`listbox-option-${index}`"
          role="option"
        >
          <p class="capitalize">{{ selection }}</p>
          <template v-if="isSelected === selection">
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600"
            >
              <!-- Heroicon name: solid/check -->
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    selections: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
  mounted() {
    this.choosedSelected(this.value ? this.value : this.selections[0])
  },
  data() {
    return {
      isSelected: '',
      show: false,
    }
  },
  methods: {
    choosedSelected(item) {
      this.isSelected = item
      this.$emit('input', item)
      this.show = false
    },
    away() {
      if (`#select-${this._uid}` !== document.activeElement.id) {
        this.show = false
      }
    },
  },
}
</script>
