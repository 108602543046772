export default {
  data() {
    return {
      palettes_color: [
        '#356390',
        '#00b7ab',
        '#FFB624',
        '#414141',
        '#321E6A',
        '#E53636',
        '#B09AE9',
        '#0BE096',
        '#ACACAC',
        '#222222',
      ],
    }
  },
  methods: {
    getNestedObject(obj, ...args) {
      return args.reduce((obj, level) => obj && obj[level], obj)
    },
    isExist(valeur) {
      return typeof valeur !== 'undefined' && valeur !== null
    },
    copyObject(obj) {
      return Object.assign({}, obj)
    },
    getCoordinates(coordinates) {
      var new_coordinates = []
      coordinates.forEach(function iter(element) {
        if (Array.isArray(element[0][0])) {
          element.forEach(iter)
        } else {
          new_coordinates.push(element)
          return
        }
      })
      return new_coordinates
    },
    getSurfaceMesurer(geojson) {
      return this.roundDouble(this.$turf.area(geojson))
    },
    getSurfaceMultiGeojson(polygones) {
      let total = 0
      polygones.forEach((polygone) => {
        total += this.$turf.area(polygone)
      })
      return this.roundDouble(total)
    },
    roundDouble(number) {
      return Math.round((number * 10) / 10)
    },
  },
}
