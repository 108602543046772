export default {
  data() {
    return {
      surface_mesurer: 0,
      surface: 0,
      nombre_bati: 0,
      surface_non_bati: null,
      donnee_imprecise: 'Donnée publique imprécise',
      surface_cadastrale_exist: true,
    }
  },

  methods: {
    SumSurfaceCadastrale(all_parcelles) {
      let vm = this
      let surface = 0
      all_parcelles.forEach((parcelle) => {
        surface += vm.getSurfaceCadastrale(parcelle)
      })
      return surface
    },
    getSurfaceCadastrale(parcelle) {
      let contenance = parcelle.properties.contenance
      let surface = contenance !== '' ? contenance : 0
      return surface
    },
    getIntersectParcelles(allParcelles, parcelle_selected) {
      let vm = this
      var lineIntersect = null
      allParcelles.some(function (parcelle) {
        lineIntersect = vm.$turf.booleanOverlap(parcelle_selected, parcelle)
        return lineIntersect
      })
      return lineIntersect
    },
    getRefParcelle(parcelle) {
      return parcelle.properties.section + '' + parcelle.properties.numero
    },
    addRefCadastaleToPropertiesFeaturesAutour(
      features_autour,
      parcelle_principale,
    ) {
      let index_parcelle_principale = 0
      features_autour.map(function (feature, index) {
        if (parcelle_principale.id === feature.parcelle.id) {
          index_parcelle_principale = index
        }
        features_autour[index].parcelle.properties.id = feature.parcelle.id
      })

      return {
        features_autour: features_autour,
        index_parcelle_principale: index_parcelle_principale,
      }
    },
    initParcellesVoisines(
      parcelles_voisines,
      color = this.orange,
      is_bati = false,
    ) {
      let vm = this
      parcelles_voisines.forEach(function (parcelle) {
        let reference_cadastrale_selected = !is_bati
          ? parcelle.properties.id
          : parcelle.id
        let options = vm.copyObject(vm.options_polygone)

        options.color = color
        vm.addPolygonsToMap(
          parcelle,
          vm.concatSourceAndLayersWithRef(
            ...vm.init_source_and_layers_parcelles_voisines,
            reference_cadastrale_selected,
          ),
          options,
        )
      })
    },

    getParcelleWithBuffer(parcelle) {
      return this.$turf.buffer(parcelle, 0.0003)
    },
    getParcellesAutour(features_autour) {
      return features_autour.map(function (feature) {
        return feature.parcelle
      })
    },
    addParcellesAutour(parcelles_autour) {
      let options = this.copyObject(this.options_polygone)
      options.color = this.grey
      options.is_dash = false
      options.is_multi_geojson = true
      this.addPolygonsToMap(
        parcelles_autour,
        this.source_layers_selected_parcelles_autour,
        options,
      )
    },
    resetParcelleVoisines(is_dash = false) {
      this.removeSourceAndLayers(...this.source_and_layers_parcelles_aggregate)
      this.form.informations.parcelles_voisines.forEach((parcelle) => {
        this.removeSourceAndLayers(
          ...this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_parcelles_voisines,
            parcelle.properties.id,
          ),
        )
      })
      if (is_dash) {
        this.initParcellesVoisines(this.form.informations.parcelles_voisines)
      } else {
        this.form.informations.parcelles_voisines = []
      }
    },

    getFeaturesExist(all_parcelles, features_autour) {
      let features_autour_exist = []
      let ids_parcelles = all_parcelles.map(
        (parcelle) => parcelle.properties.id,
      )

      features_autour.forEach(function (feature_autour) {
        if (ids_parcelles.includes(feature_autour.parcelle.properties.id)) {
          features_autour_exist.push(feature_autour)
        }
      })
      return features_autour_exist
    },
  },
}
