<template>
  <label
    :class="[
      classes,
      value === label
        ? 'border-blue-ice-400 text-blue-ice-400'
        : 'border-promy-gray-250 hover:border-blue-ice-400 text-dark-grey-400 hover:text-blue-ice-400',
    ]"
    class="border pl-3 pr-6 py-2 rounded-lg font-semibold font-poppins"
  >
    <input
      type="radio"
      :id="id"
      :value="label"
      @click="check"
      v-model="value"
    />
    <span class="m-0" for="id" />
    <div>
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    classes: {
      type: String,
      default: 'flex-row',
    },
  },
  methods: {
    check() {
      this.$emit('change', this.label)
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  @apply flex cursor-pointer relative overflow-hidden;
  margin-bottom: 0.375em;

  input {
    position: absolute;
    left: -9999px;
    &:checked + span {
      &:before {
        box-shadow: inset 0 0 0 4px theme('colors.blue-ice.400');
      }
    }
  }
  span {
    @apply flex items-center mr-2;
    border-radius: 99em;
    transition: 0.25s ease;
    &:before {
      @apply flex w-4 h-4 rounded-full bg-white;
      flex-shrink: 0;
      content: '';
      transition: 0.25s ease;
      box-shadow: inset 0 0 0 2px theme('colors.promy-gray.400');
    }
  }
}
</style>
