<template>
  <validation-provider
    class="relative flex flex-col"
    :rules="rules"
    v-slot="{ errors }"
  >
    <div class="relative">
      <input
        type="search"
        :id="`#search-data-${_uid}`"
        class="btn-auto-complete relative w-full px-3 py-2.72 pr-10 text-left bg-white border rounded-md shadow-sm outline-none cursor-default border-promy-gray-500 focus:outline-none focus:ring-1 sm:text-sm"
        :placeholder="placeholder"
        autocomplete="off"
        :value="value"
        @input="handleInput($event.target.value)"
        @focus="open = true"
      />
      <template v-if="is_loading">
        <div class="loader"></div>
      </template>
    </div>
    <div
      class="flex z-10"
      v-show="open && list.length"
      @mouseover="
        () => {
          hover_drop_down = true
        }
      "
      @mouseleave="
        () => {
          hover_drop_down = false
        }
      "
    >
      <div
        class="absolute bg-promy-gray-120 w-full"
        v-click-outside="away"
        style="background-color: transparent"
      >
        <ul class="py-4 shadow-md max-h-43 overflow-y-auto bg-white">
          <li
            v-for="(item, index) in list"
            :key="index"
            class="relative py-2 pl-3 transition-colors duration-150 cursor-pointer select-none text-promy-gray-700 pr-9 hover:bg-promy-gray-100"
            @click="setItem(item)"
          >
            <p class="capitalize">{{ getNestedObject(item) }}</p>
          </li>
        </ul>
      </div>
    </div>
    <span
      v-show="errors[0] && !hover_drop_down"
      class="mt-1 text-xs text-promy-red-400"
      >{{ errors[0] }}</span
    >
  </validation-provider>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: {
    rules: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    list: {
      type: Array,
      required: true,
    },
    value: {},
    is_loading: {
      type: Boolean,
      default: false,
    },
    args: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      open: false,
      hover_drop_down: false,
    }
  },
  methods: {
    away() {
      if (`#search-data-${this._uid}` !== document.activeElement.id) {
        this.open = false
      }
    },
    setItem(item) {
      this.open = false
      this.$emit('setItem', item)
    },
    handleInput(value) {
      this.$emit('input', value)
    },
    getNestedObject(obj) {
      return this.args.reduce((obj, level) => obj && obj[level], obj)
    },
  },
}
</script>
<style lang="scss" scoped>
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #10a9ae; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  top: 13px;
  right: 13px;
  position: absolute;
}
.btn-auto-complete {
  border-radius: 0.375rem !important;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  appearance: none !important;
}
input {
  -webkit-appearance: none;
}
</style>
