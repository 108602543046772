export default {
  data() {
    return {
      green: '#00C9BC',
      green_light: '#0BE096',
      orange: '#FFB143',
      grey: '#A0A0A0',
      blue: '#356390',
      purple: '#321E6A',
      options_polygone: {
        color: '#45CCDF',
        is_dash: false,
        is_multi_geojson: false,
        opacity: 0.3,
      },
    }
  },
  methods: {
    createMap(coordinates, zoom = 18, id = 'mapbox') {
      let temp_map = {}

      temp_map = new this.$mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11?optimize=true',
        center: coordinates,
        zoom: zoom,
      })

      temp_map.addControl(
        new this.$mapboxgl.NavigationControl({
          showZoom: id === 'mapbox' ? true : false,
          showCompass: false,
          visualizePitch: false,
        }),
        'top-right',
      )
      // disable map rotation using right click + drag
      temp_map.dragRotate.disable()

      // disable map rotation using touch rotation gesture
      temp_map.touchZoomRotate.disableRotation()

      temp_map.resize()
      return temp_map
    },
    addPolygonsToMap(
      polygons,
      id_source_and_layers,
      options = this.options_polygone,
    ) {
      let source_and_layers = {
        id_source: id_source_and_layers[0],
        id_background_layer: id_source_and_layers[1],
        id_outline_layer: id_source_and_layers[2],
      }
      if (options.is_multi_geojson)
        this.addMultiGeojsonToMap(source_and_layers.id_source, polygons)
      else this.addGeojsonToMap(source_and_layers.id_source, polygons)
      this.addBackgroundLayerToGeojson(
        source_and_layers.id_background_layer,
        source_and_layers.id_source,
        options.color,
        options.opacity,
      )
      this.addOutlineLayerToGeojson(
        source_and_layers.id_outline_layer,
        source_and_layers.id_source,
        options.color,
        options.is_dash,
      )
    },
    addMultiGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: geojson,
        },
        generateId: true,
      })
    },
    addBackgroundLayerToGeojson(id_layer, source_layer, color, opacity = 0.3) {
      this.map.addLayer({
        id: id_layer,
        type: 'fill',
        source: source_layer,
        paint: {
          'fill-color': color,
          'fill-opacity': opacity,
        },
      })
    },
    addGeojsonToMap(name_source, geojson) {
      this.map.addSource(name_source, {
        type: 'geojson',

        data: geojson,
      })
    },
    addOutlineLayerToGeojson(id_layer, source_layer, color, is_dash = false) {
      let layout = {}
      let line_dasharray = [1]
      if (is_dash) {
        layout = {
          'line-join': 'round',
          'line-cap': 'round',
        }
        line_dasharray = [0.1, 1.8]
      }
      this.map.addLayer({
        id: id_layer,
        type: 'line',
        source: source_layer,
        layout: layout,
        paint: {
          'line-color': color,
          'line-width': 4,
          'line-dasharray': line_dasharray,
          'line-opacity': 1,
        },
      })
    },
    removeLayers() {
      let all_layers = this.map.getStyle().layers.map((layer) => {
        return {
          id: layer.id,
          source: layer.source,
        }
      })
      let additional_layer = all_layers.filter((layer) => {
        return layer.id.split('-')[0] === 'promy'
      })

      additional_layer.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id)
        }
      })
      additional_layer.forEach((layer) => {
        if (this.map.getSource(layer.source)) {
          this.map.removeSource(layer.source)
        }
      })
    },
    fitBoundsGeojsonInMap(geojson, padding = 40) {
      var bbox = this.$turf.bbox(geojson)
      this.map.fitBounds(bbox, {
        padding: padding,
      })
    },
    addParcellesAutour(parcelles_autour) {
      let options = Object.assign({}, this.options_polygone)
      options.color = this.grey
      options.is_dash = false
      options.is_multi_geojson = true
      this.addPolygonsToMap(
        parcelles_autour,
        this.source_layers_selected_parcelles_autour,
        options,
      )
    },
    removeSourceAndLayers(
      id_source,
      id_background_layer,
      id_outline_layer = null,
    ) {
      if (this.map) {
        if (this.isExist(this.map.getLayer(id_background_layer)))
          this.map.removeLayer(id_background_layer)
        if (this.isExist(this.map.getLayer(id_outline_layer))) {
          this.map.removeLayer(id_outline_layer)
        }
        if (this.map.getSource(id_source)) {
          this.map.removeSource(id_source)
        }
      }
    },
    layerExist(id_layer) {
      return typeof this.map.getLayer(id_layer) !== 'undefined'
    },
    concatSourceAndLayersWithRef(
      id_source,
      id_background_layer,
      id_outline_layer,
      ref,
    ) {
      return [
        id_source + '-' + ref,
        id_background_layer + '-' + ref,
        id_outline_layer + '-' + ref,
      ]
    },
  },
}
