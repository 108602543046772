<template>
  <div class="flex flex-col">
    <div
      class="max-w-4xl w-full flex flex-col self-center space-y-4 py-6 lg:px-6"
    >
      <h2>Mentions Légales</h2>

      <div class="condition">
        <h3>1. ÉDITEUR</h3>
        <div class="content">
          <p>La société Promy,</p>
          <p>
            Société́ par actions simplifiées (SAS) au capital de 10000 euros.
          </p>
          <p>
            Inscrite sur le Greffe du Tribunal de Commerce de LYON, sous le
            numéro 847 831 690 R.C.S. LYON.
          </p>
          <p>
            Dont le siège social est situé au 6, place de la Baleine – 69005
            LYON
          </p>
          <p>
            Téléphone : 04 26 00 89 70 <br />
            Adresse mail :
            <a href="mailto:contact@promy.fr">contact@promy.fr</a>
          </p>
          <p>Numéro de TVA intracommunautaire : FR76847831690</p>
          <p>
            Numéro de SIRET : 84783169000015 Siège social : 6 Place de la
            Baleine 69005 Lyon.
          </p>
          <p>
            Le directeur de la publication du Site internet est Monsieur Romain
            Solenne en sa qualité de président.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>2. HÉBERGEUR</h3>
        <div class="content">
          <p>
            Le site internet est hébergé par la société Google Cloud
            Platform.<br />
            Adresse : Gordon House, Barrow Street, Dublin 4, Irlande
          </p>
          <p>Le site est la propriété exclusive de la société Promy.</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  created() {
    document.title = 'Mentions Légales | Promy'
  },
}
</script>
<style lang="scss" scoped>
h2 {
  @apply font-poppins text-dark-purple-400 font-bold text-2xl;
}
h3 {
  @apply font-poppins text-dark-purple-400 font-bold text-xl mb-2;
}

a {
  @apply text-blue-ice-400;
  &:hover {
    @apply underline;
  }
}
.intro,
.content p {
  @apply font-poppins;
  color: #333;
}

.content,
.intro {
  @apply flex flex-col space-y-3;
}
</style>
