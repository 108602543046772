<template>
  <div class="flex flex-col">
    <div
      class="max-w-4xl w-full flex flex-col self-center space-y-4 py-6 lg:px-6"
    >
      <h2>Politique de protection des données</h2>

      <div class="condition">
        <h3>1. OBJECTIF POURSUIVI</h3>
        <div class="content">
          <p>
            La présente politique de protection des données a pour objectif de
            vous informer sur les engagements et mesures pratiques pris par
            Promy afin de veiller au respect de vos données à caractère
            personnel.
          </p>
          <p>
            La présente politique de confidentialité pourra évoluer en fonction
            du contexte légal et réglementaire et des recommandations de la
            CNIL.
          </p>
          <h4>1.1 Définitions</h4>
          <p>
            Par « Membre », nous entendons toute personne physique inscrite à
            l’un des Services.
          </p>
          <p>
            Par « Visiteur », nous entendons toute personne physique accédant
            aux Services sans être inscrit à l’un de ces Services.
          </p>
          <p>
            Par « Données Personnelles », nous entendons toute information se
            rapportant aux Membres, Visiteurs et/ou toute autre personne
            physique identifiée ou identifiable au sens de la réglementation
            applicable sur les données personnelles telles que nom, âge, adresse
            postale, adresse e-mail, numéro de téléphone.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>2. CHAMP D’APPLICATION</h3>
        <div class="content">
          <p>
            La présente politique de confidentialité s’applique lorsque vous
            visitez notre site Web, lorsque vous accédez à nos produits et
            services ou bien encore lorsque vous participez à des événements
            Promy (ci-après ensemble désigné le ou les « Service(s) »).
          </p>
          <p>
            Promy est ainsi responsable de vos données personnelles en sa
            qualité de responsable de traitement.
          </p>
          <p>
            La présente politique de confidentialité peut être complétée par des
            éléments supplémentaires précisés dans tout ou partie d’un Service
            fourni.
          </p>
          <p>
            Le traitement des Données Personnelles des Membres et Visiteurs est
            réalisé en conformité avec la réglementation applicable sur les
            données personnelles et en particulier le Règlement européen
            n°2016/679 du 27 avril 2016 relatif à la protection des personnes
            physiques à l’égard du traitement des données à caractère personnel
            et à la libre circulation de ces données, la Loi n°78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux libertés
            et toute autre réglementation applicable aux Services.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>3. TYPOLOGIE DES DONNÉES COLLECTÉES</h3>
        <div class="content">
          <p>
            Promy veille à ne collecter et ne traiter que des données
            strictement nécessaires au regard de la finalité pour laquelle elles
            sont traitées (minimisation des données).
          </p>
          <p>
            Lorsque vous accédez à l’un de nos Services, vous pouvez être amené
            à fournir des données personnelles en remplissant des formulaires
            d’enregistrement : nous y indiquons quels types de données
            personnelles sont exigés.
          </p>
          <p>
            Le traitement des données de votre compte personnel est basé sur le
            consentement que vous nous donnez lorsque vous créez votre compte
            Promy.
          </p>
          <p>
            Vous pouvez choisir de ne pas soumettre les données demandées, mais
            cela pourra limiter ou parfois empêcher votre accès au Service.
          </p>
          <p>Les données personnelles sont collectées de trois manières :</p>
          <ul>
            <li>Celles que vous fournissez ;</li>
            <li>
              Celles que nous obtenons par l’usage que vous faites de nos
              Services ;
            </li>
            <li>Et enfin celles que nous obtenons de sources tierces.</li>
          </ul>
          <h4>3.1 Les données que vous nous fournissez</h4>
          <p>
            Les données personnelles collectées directement auprès de vous
            dépendent de la relation que vous avez avec le Service et peuvent
            inclure :
          </p>
          <ul>
            <li>Des données de contact : l’adresse e-mail ;</li>
            <li>
              Des données relatives au propriétaire : nom, prénom, mail,
              téléphone, adresse ;
            </li>
            <li>
              Les commentaires et autres informations que vous nous fournissez,
              y compris les requêtes de recherches, questions et informations
              que vous envoyez au service sollicité.
            </li>
          </ul>
          <h4>
            3.2. Les données de l’utilisation du service, y compris les cookies
          </h4>
          <p>
            Par ailleurs, nous pouvons collecter automatiquement (site
            internet/application) des informations sur la manière dont vous
            interagissez avec lui, y compris :
          </p>
          <ul>
            <li>
              Les informations concernant l’ordinateur, l’environnement de
              connexion, à savoir l’adresse IP, le type et la version du
              navigateur, le système d’exploitation et autres logiciels
              installés dans votre environnement, la plateforme mobile, le ou
              les identifiants techniques, rapports d’erreur et données
              d’exécution ;
            </li>
            <li>
              Les données d’utilisation telles que les fonctions que vous avez
              utilisées, les configurations que vous avez sélectionnées, les
              données sur lesquelles vous avez cliqué, y compris la date et
              l’heure et les pages consultées, les termes recherchés, les pages
              visitées et recherchées sur le Service ;
            </li>
            <li>
              Les informations géographiques de Services basées sur la
              localisation de l’utilisateur pour vous fournir des contenus plus
              pertinents en fonction de votre situation géographique où que vous
              soyez dans le monde.
            </li>
          </ul>
          <p>
            Nous collectons ces données par le biais de nos serveurs,
            l’utilisation de cookies et diverses technologies.
          </p>
          <p>
            Les cookies sont de petits fichiers textes qui peuvent être lus par
            un serveur Web du domaine Promy qui a placé le cookie sur votre
            disque dur. Nous pouvons utiliser des cookies et d’autres
            technologies pour stocker vos préférences et configurations, vous
            aider lors de l’enregistrement et analyser les opérations du site.
          </p>
          <p>
            Vous pouvez contrôler les cookies au moyen de vos configurations de
            navigateur et de plusieurs outils.
          </p>
          <p>
            Toutefois, si vous bloquez certains cookies, vous ne pourrez plus
            vous inscrire, vous connecter, accéder à certaines parties ou
            utiliser pleinement le Service.
          </p>
          <h4>3.3. Les données recueillies auprès d’autres sources</h4>
          <p>
            Nous pouvons également obtenir vos coordonnées et autres
            informations personnelles auprès d’autres parties tierces, y compris
            :
          </p>
          <ul>
            <li>
              Des entreprises avec lesquelles nous offrons des services communs
              sous réserve que votre consentement ait été donné au tiers ; et/ou
            </li>
            <li>
              Des sources accessibles publiquement et auprès des fournisseurs de
              données de la part desquels nous obtenons des données afin de
              valider et de compléter les informations que nous possédons.
            </li>
          </ul>
        </div>
      </div>

      <div class="condition">
        <h3>4. FINALITÉS DES TRAITEMENTS</h3>
        <div class="content">
          <p>
            Selon les relations établies avec Promy, nous utilisons vos données
            personnelles pour :
          </p>
          <ul>
            <li>
              Fournir, activer et gérer votre accès au Service et votre
              utilisation de celui-ci ; Fournir un support technique, un service
              ou produit complémentaire et permettre ainsi le bon fonctionnement
              du Service et sa sécurisation ;
            </li>
            <li>
              Améliorer le Service et nos autres produits, nos événements et en
              développer de nouveaux;
            </li>
            <li>
              Répondre à vos demandes, recherches, commentaires et
              préoccupations ;
            </li>
            <li>
              Notifier les modifications, mises à jour et autres annonces
              relatives au Service et à nos autres produits et services ;
            </li>
            <li>
              Fournir des messages promotionnels et autres informations sur nos
              produits, événements et services, et l’ensemble des nouveautés
              rattachées à Promy;
            </li>
            <li>
              Vous inviter à participer à des enquêtes sur l’usage du Service ;
            </li>
            <li>
              Identifier les tendances d’utilisation et développer l’analyse de
              données, y compris pour la recherche, l’audit, le reporting et
              d’autres opérations commerciales qui comprennent la détermination
              de l’efficacité de nos campagnes promotionnelles et l’évaluation
              de la performance commerciale ;
            </li>
            <li>
              Nous conformer à nos obligations légales, résoudre d’éventuels
              litiges et faire respecter nos contrats.
            </li>
          </ul>
        </div>
      </div>
      <div class="condition">
        <h3>5. DESTINATAIRES DES DONNÉES</h3>
        <div class="content">
          <p>
            Promy ne communique vos données à caractère personnel qu’à des
            destinataires habilités et déterminés :
          </p>
          <ul>
            <li>Les salariés et dirigeants de Promy.</li>
            <li>
              Ainsi qu’à ses éventuels prestataires de services tiers, agissant
              en tant que sous-traitants, pour exécuter des prestations
              relatives aux services de Promy (notamment des services
              d’hébergement, de stockage, d’analyses, de traitement de données,
              de gestion de bases de données ou encore de maintenance
              informatique) tels Google pour l’hébergement.
            </li>
          </ul>
          <p>
            Promy s’engage à recueillir votre consentement préalable, notamment
            par voie d’opt-in (case à cocher), avant de transmettre vos données
            à des partenaires à des fins de prospection commerciale.
          </p>
          <p>
            Vos données ne sont en aucun cas commercialisées sans votre
            consentement.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>6. DURÉE DE CONSERVATION DE VOS DONNÉES PERSONNELLES</h3>
        <div class="content">
          <p>
            Nous retenons vos données personnelles aussi longtemps que
            nécessaire pour fournir le Service et exécuter les obligations que
            vous nous avez demandées, ou à d’autres fins essentielles telles que
            le respect de nos obligations légales, la tenue d’archives
            commerciales et financières, le règlement de différends, le maintien
            de la sécurité, la détection et la prévention de la fraude et d’abus
            et l’exécution du contrat nous liant.
          </p>
          <p>
            À compter de la clôture du compte, à notre initiative ou à la vôtre,
            vos données personnelles sont conservées pendant une période de deux
            (2) ans.
          </p>
          <p>
            À l'expiration de ce délai, Promy s'engage à détruire vos données
            personnelles.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>7. HEBERGEMENT DES DONNÉES</h3>
        <div class="content">
          <p>
            Promy prend des mesures, y compris par le biais de contrats, dont le
            but est d’assurer que les informations continuent à être protégées
            là où elles sont localisées en toute cohérence avec les normes de
            protection imposées par la réglementation en vigueur.
          </p>
          <p>
            Promy bénéficie d'un centre de données et d'une architecture réseau
            conçus pour répondre aux exigences des organisations les plus
            pointilleuses en termes de sécurité.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>8. SÉCURITÉ DES DONNÉES</h3>
        <div class="content">
          <p>
            Promy assure la sécurité de vos données à caractère personnel en
            mettant en place une protection des données renforcée par
            l’utilisation de moyens de sécurisation physiques et logistiques.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>9. PROTECTION DE LA VIE PRIVÉE DES ENFANTS</h3>
        <div class="content">
          <p>
            Promy ne collecte pas sciemment des informations d’enfants de moins
            de 16 ans et par principe les sites Internet de Promy ne s’adressent
            pas aux enfants de moins de 16 ans.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>10. VOS DROITS</h3>
        <div class="content">
          <p>
            Vous avez des droits garantis par les lois sur la protection de la
            vie privée et des données en Europe et dans certains autres pays, et
            vous pouvez en bénéficier gratuitement :
          </p>
          <ul>
            <li>L’accès à vos informations personnelles;</li>
            <li>
              La rectification ou suppression de vos données personnelles ;
            </li>
            <li>
              La restriction de notre traitement de vos données personnelles ;
            </li>
            <li>L’objection à notre traitement ;</li>
            <li>
              La portabilité des données transmises par l’utilisateur et non
              celles générées par le Service.
            </li>
          </ul>
          <p>
            Vous seul pouvez exercer ces droits sur vos propres données en vous
            adressant à : Promy 6 Place de la Baleine
            <a href="mailto:contact@promy.fr">contact@promy.fr</a>
          </p>
          <p>
            Vous pouvez également déposer une plainte auprès de la Commission
            Nationale de l'Informatique et des Libertés (CNIL), régulateur des
            données personnelles en France.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>11. Lien hypertextes</h3>
        <div class="content">
          <p>
            Les Services peuvent contenir des liens hypertextes vers des sites
            internet tiers qui ne sont pas régis par la présente Politique de
            confidentialité et de sécurité ou les conditions générales
            d’utilisation des Services. PROMY ne dispose d'aucun contrôle quant
            aux contenus des sites internet tiers référencés par des liens
            hypertextes. Ces sites internet sont édités par des sociétés tierces
            indépendantes de PROMY. PROMY ne saurait en conséquence assumer une
            quelconque responsabilité quant aux contenus, publicités, produits,
            services ou toute autre information ou donnée, disponibles sur ou à
            partir de ces sites. En conséquence, vous reconnaissez être seul
            responsable de l’accès et de l’utilisation de ces sites. PROMY ne
            pourra être tenue responsable de tous dommages ou pertes avérés ou
            allégués consécutifs ou en relation avec l'utilisation ou le fait
            d'avoir fait confiance aux contenus, à des biens ou des services
            disponibles sur ces sites.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>12. LANGUES</h3>
        <div class="content">
          <p>
            La version d'origine de cette politique a été rédigée en langue
            française.
          </p>
          <p>
            Ainsi, la version française sera considérée comme la version
            officielle. En cas de litige concernant la présente politique, la
            version française prévaudra sur la version anglaise et toute autre
            version.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>13. MODIFICATIONS</h3>
        <div class="content">
          <p>
            Nous mettrons à jour la présente politique de confidentialité
            régulièrement. Toute modification sera postée sur cette page avec
            une date de révision à jour.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>14. CONTACT</h3>
        <div class="content">
          <p>
            Si vous avez des commentaires, questions ou demandes concernant la
            présente politique ou le traitement de vos données, veuillez nous
            contacter en vous adressant à : Promy - 6 Place de la Baleine -
            69005 Lyon <a href="mailto:contact@promy.fr">contact@promy.fr</a>
            <br />
            en précisant dans l’objet du courrier « RGPD » et en joignant la
            copie de votre justificatif d’identité.
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  created() {
    document.title = 'Politique de protection des données | Promy'
  },
}
</script>
<style lang="scss" scoped>
h2 {
  @apply font-poppins text-dark-purple-400 font-bold text-2xl;
}
h3 {
  @apply font-poppins text-dark-purple-400 font-bold text-xl mb-2;
}
h4 {
  @apply font-poppins text-dark-purple-400 font-bold text-lg;
  text-indent: 0.5rem;
}

a {
  @apply text-blue-ice-400;
  &:hover {
    @apply underline;
  }
}
.intro,
.content p {
  @apply font-poppins;
  color: #333;
}

.content,
.intro {
  @apply flex flex-col space-y-3;
}

.content {
  ul {
    @apply pl-6 font-poppins text-sm;
    list-style: unset;
    color: #333;
  }
}
</style>
