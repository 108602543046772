<template>
  <div>
    <div
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex sm:mx-6"
    >
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div class="mt-4 mx-6">
            <h1 class="text-dark-grey-400 font-medium text-xl font-poppins">
              <slot name="message_title">Confirmation</slot>
            </h1>
          </div>

          <!--body-->
          <div class="relative px-6 flex-auto">
            <p
              class="my-4 text-dark-grey-400 text-lg font-arial leading-relaxed"
            >
              <slot name="message_body"></slot>
            </p>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-center w-full my-4 rounded-b space-x-4"
          >
            <PromyButton
              btnText="Quitter"
              bg_color="grey-400"
              @click="toggleModal(true)"
            />
            <PromyButton btnText="Continuer" @click="toggleModal(false)" />
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleModal(is_valide) {
      is_valide ? this.$emit('isValide') : this.$emit('isCancel')
    },
  },
}
</script>
