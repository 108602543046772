import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('email', {
  ...email,
  message: "L'email n'est pas valide",
})

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire',
})

extend('confirmedBy', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'le mot de passe confirmé incorrect',
})

extend('phoneNumber', {
  validate(value) {
    let pattern = /^(0[0-9]{9}|[1-9][0-9]{8})$/
    return pattern.test(value)
  },
  message: 'N° de téléphone non valide',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
