import Vue from 'vue'
import Vuex from 'vuex'
import tunnel from './modules/tunnel'
import initMap from './modules/initMap'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tunnel,
    initMap,
  },
})
