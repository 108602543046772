import axios from 'axios'
import Vue from 'vue'

const transport = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
})
Vue.prototype.$http = transport
Vue.prototype.$url_cadastre = process.env.VUE_APP_CADASTRE_URL

export default transport
