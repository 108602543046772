import Vue from 'vue'

const requireComponent = require.context(
  '@/components',
  true,
  /[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})

const requirePages = require.context('@/pages', true, /[A-Z]\w+\.(vue|js)$/)

requirePages.keys().forEach((fileName) => {
  const componentConfig = requirePages(fileName)
  const componentName = fileName
    .split('/')
    .pop()
    .replace(/\.\w+$/, '')
  Vue.component(componentName, componentConfig.default || componentConfig)
})
