<template>
  <PromyButton
    class="w-52 sm:w-full"
    :btnText="!cadastre.is_parcelle_changed ? 'Changer la parcelle' : 'Valider'"
    @click="addListenerClickChangeParcelle"
    :bg_color="!cadastre.is_parcelle_changed ? 'blue-ice-400' : 'grey-400'"
  />
</template>

<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/parcelle'
import helpers from '@/mixins/helpers'
import sourcesAndLayers from '@/mixins/sourcesAndLayers'
import batiment from '@/mixins/batiment'

export default {
  mixins: [helpers, Mapbox, parcelle, sourcesAndLayers, batiment],
  props: {
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
    map: {
      required: true,
    },
  },
  methods: {
    addListenerClickChangeParcelle() {
      if (this.cadastre.is_parcelle_aggregate) {
        this.$toaster.warning('Veuillez valider vos choix de parcelles ')
      } else if (!this.cadastre.is_parcelle_changed) {
        this.cadastre.is_parcelle_changed = true
        this.map.on('click', this.selectParcelle)
      } else {
        this.map.off('click', this.selectParcelle)
        this.cadastre.is_parcelle_changed = false
      }
    },
    selectParcelle(e) {
      var features = this.map.queryRenderedFeatures(e.point)
      var parcelle_selected = this.cadastre.parcelle_autour[features[0].id]
      parcelle_selected.id = features[0].id
      if (
        parcelle_selected.properties.id !==
        this.form.informations.current_parcelle.properties.id
      ) {
        this.resetParcelleVoisines()
      }

      this.form.informations.current_parcelle = parcelle_selected
      this.removeSourceAndLayers(...this.source_layers_selected_parcelle)
      this.removeSourceAndLayers(...this.first_source_layers_parcelle)
      this.addPolygonsToMap(
        this.form.informations.current_parcelle,
        this.source_layers_selected_parcelle,
      )
    },
  },
  watch: {
    'form.informations.current_parcelle': {
      handler(parcelle) {
        if (parcelle) {
          this.form.informations.coords_parcelle_principale =
            this.$turf.pointOnFeature(parcelle).geometry.coordinates
          this.form.informations.batiments = this.getAllBatis()
        }
      },
    },
  },
  beforeDestroy() {
    if (this.cadastre.is_parcelle_changed) {
      this.addListenerClickChangeParcelle()
    }
  },
}
</script>
