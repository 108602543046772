<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around px-16 pb-8 lg:px-8 max-w-xl xl:w-144"
      >
        <validation-observer v-slot="{ invalid }">
          <div class="flex flex-col space-y-6 w-full">
            <div
              class="text-dark-grey-400 font-medium font-poppins text-3xl sm:text-2xl mb-5 sm:leading-7 leading-9"
            >
              Vous avez une question ? Vous souhaitez obtenir un renseignement ?
              Aucun problème, envoyez-nous un message. Nous vous répondons
              rapidement.
            </div>
            <div>
              <TextInput
                label="Prénom"
                placeholder="Prénom"
                rules="required"
                :value="form.prenom"
                @input="
                  (val) => {
                    form.prenom = val
                  }
                "
              />
            </div>
            <div>
              <TextInput
                label="Nom"
                placeholder="Nom"
                rules="required"
                :value="form.nom"
                @input="
                  (val) => {
                    form.nom = val
                  }
                "
              />
            </div>
            <div>
              <TextInput
                label="Email"
                placeholder="Email"
                rules="required|email"
                :value="form.email"
                @input="
                  (val) => {
                    form.email = val
                  }
                "
              />
            </div>
            <div>
              <ProTextarea
                label="Message"
                placeholder="Description"
                rules="required"
                :value="form.message"
                v-model.number="form.message"
                @input="
                  (val) => {
                    form.message = val
                  }
                "
                type="number"
              />
            </div>
            <div>
              <PromyButton
                class="sm:text-xs"
                btnText="Envoyer"
                @click="sendContact(invalid)"
              />
            </div>
          </div>
        </validation-observer>
      </div>
    </template>
    <template slot="right-side">
      <div
        style="background-image: url(/images/img-header.jpg)"
        class="bg-center bg-cover bg-no-repeat lg:hidden"
      />
    </template>
  </LayoutTunnel>
</template>
<script>
export default {
  data() {
    return {
      form: {
        prenom: '',
        nom: '',
        email: '',
        message: '',
      },
    }
  },
  methods: {
    sendContact(invalid) {
      invalid
        ? this.$toaster.warning('Tous les champs sont obligatoires.')
        : this.$http.post('contactezNous', this.form).then(
            (response) => {
              this.$toaster.success('Votre message a bien été envoyé.')
              this.form.message = null
            },
            (error) => {
              this.$toaster.warning("Votre message n'est pas envoyé.")
            },
          )
    },
  },
}
</script>
