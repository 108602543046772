<template>
  <div class="rounded-lg flex flex-col w-full px-6 py-4" :class="`bg-${color}`">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'promy-orange-100',
    },
  },
}
</script>
