import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from '@/router/index'

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY,
  router,
  debug: {
    enabled: false,
    trace: false,
    sendHitTask: true,
  },
  disabled: false,
  autoTracking: {
    pageviewOnLoad: true,
    //removes query string
    transformQueryString: false,
    //removes base path
    prependBase: false,

    shouldRouterUpdate(to, from) {
      // Here I'm allowing tracking only when
      // next route path is not the same as the previous
      return to.path !== from.path
    },
    pageviewTemplate(route) {
      return {
        page: route.path,
        title: route.name,
        location: window.location.href,
      }
    },
  },
})
