<template>
  <div>
    <PromyButton
      class="w-52 sm:w-full"
      :btnText="
        !cadastre.is_parcelle_aggregate ? 'Ajouter Une parcelle' : 'Valider'
      "
      @click="addListenerClickAggregateParcelle"
      :bg_color="!cadastre.is_parcelle_aggregate ? 'blue-ice-400' : 'grey-400'"
    />
  </div>
</template>

<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/parcelle'
import helpers from '@/mixins/helpers'
import sourcesAndLayers from '@/mixins/sourcesAndLayers'
import batiment from '@/mixins/batiment'

export default {
  data() {
    return {
      max_parcelles_aggregate: 20,
    }
  },
  mixins: [helpers, Mapbox, parcelle, sourcesAndLayers, batiment],
  props: {
    form: {
      type: Object,
      required: true,
    },
    map: {
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },

  methods: {
    addListenerClickAggregateParcelle() {
      if (this.cadastre.is_parcelle_changed) {
        this.$toaster.warning('Veuillez valider votre parcelle ')
      } else if (this.cadastre.is_parcelle_aggregate) {
        this.map.off('click', this.selectOrUnselectParcelleVoisines)
        this.resetParcelleVoisines(true)
        this.cadastre.is_parcelle_aggregate = false
      } else {
        this.map.on('click', this.selectOrUnselectParcelleVoisines)
        this.cadastre.is_parcelle_aggregate = true
      }
    },
    selectParcelleVoisines(
      current_parcelle,
      parcelles_voisines,
      ref_layer_parcelle_selected,
      selected_is_not_current_parcelle,
      reference_cadastrale_selected,
      parcelle_selected,
    ) {
      var is_intersect = this.getIntersectParcelles(
        [current_parcelle, ...parcelles_voisines],
        parcelle_selected,
      )

      if (
        is_intersect &&
        !this.layerExist(ref_layer_parcelle_selected) &&
        selected_is_not_current_parcelle
      ) {
        let options = this.copyObject(this.options_polygone)
        options.color = this.orange
        options.is_dash = true
        this.addPolygonsToMap(
          parcelle_selected,
          this.concatSourceAndLayersWithRef(
            ...this.source_and_layers_parcelles_voisines,
            reference_cadastrale_selected,
          ),
          options,
        )
        return true
      }
      return false
    },
    getParcelleVoisinesWithoutParcellesRemoved(
      parcelles_voisines,
      removed_ref_cadastrale_parcelles,
    ) {
      return [
        ...parcelles_voisines.filter(
          (parcelle_voisines) =>
            !removed_ref_cadastrale_parcelles.includes(
              parcelle_voisines.properties.id,
            ),
        ),
      ]
    },

    propertieNumExist(feature) {
      return this.isExist(this.getNestedObject(feature, 'properties', 'numero'))
    },

    selectOrUnselectParcelleVoisines(e) {
      let features = this.map.queryRenderedFeatures(e.point)
      if (this.propertieNumExist(features[0])) {
        let find_parcelle_voisine =
          this.form.informations.parcelles_voisines.find(
            (parcelle) => parcelle.properties.id === features[0].properties.id,
          )
        var parcelle_selected = find_parcelle_voisine
          ? find_parcelle_voisine
          : this.cadastre.parcelle_autour[features[0].id]
        parcelle_selected.id = features[0].id // to know index parcelle in parcelle autour
        let reference_cadastrale_selected = parcelle_selected.properties.id
        let reference_cadastrale_current =
          this.form.informations.current_parcelle.properties.id

        let ref_layer_parcelle_selected =
          this.source_and_layers_parcelles_voisines[1] +
          '-' +
          reference_cadastrale_selected
        let selected_is_not_current_parcelle =
          reference_cadastrale_selected != reference_cadastrale_current

        if (
          this.layerExist(ref_layer_parcelle_selected) &&
          selected_is_not_current_parcelle
        ) {
          this.setInfosParcellesVoisines(
            this.unSelectParcelleVoisines(
              this.form.informations.current_parcelle,
              this.form.informations.parcelles_voisines,
              parcelle_selected,
              reference_cadastrale_selected,
            ),
          )
        } else if (
          this.form.informations.parcelles_voisines.length <
          this.max_parcelles_aggregate
        ) {
          this.selectParcelleVoisines(
            this.form.informations.current_parcelle,
            this.form.informations.parcelles_voisines,
            ref_layer_parcelle_selected,
            selected_is_not_current_parcelle,
            reference_cadastrale_selected,
            parcelle_selected,
          )
            ? this.setInfosParcellesVoisines(parcelle_selected)
            : ''
        } else if (
          this.form.informations.parcelles_voisines.length >=
          this.max_parcelles_aggregate
        ) {
          this.$toaster.warning(
            `vous avez le droit de sélectionner ${this.max_parcelles_aggregate} parcelles voisines maximum ! `,
          )
        }
      }
    },
    unSelectParcelleVoisines(
      current_parcelle,
      parcelles_voisines,
      parcelle_selected,
      reference_cadastrale_selected,
    ) {
      this.removeSourceAndLayers(
        ...this.concatSourceAndLayersWithRef(
          ...this.source_and_layers_parcelles_voisines,
          reference_cadastrale_selected,
        ),
      )
      let removed_ref_cadastrale_parcelles = []
      let parcelle_voisines_without_selected =
        this.getParcellesVoisinesWithoutSelected(reference_cadastrale_selected)
      let buffer_parcelle_voisines_without_selected =
        parcelle_voisines_without_selected.map((parcelle) =>
          this.getParcelleWithBuffer(parcelle),
        )
      let buffer_current_parcelle = this.getParcelleWithBuffer(current_parcelle)
      removed_ref_cadastrale_parcelles.push(parcelle_selected.properties.id)
      let union_parcelle_not_contiguous = this.$turf.union(
        buffer_current_parcelle,
        ...buffer_parcelle_voisines_without_selected,
      )
      if (union_parcelle_not_contiguous.geometry.type === 'MultiPolygon') {
        removed_ref_cadastrale_parcelles.push(
          ...this.getRefsParcellesForDeleted(
            union_parcelle_not_contiguous,
            current_parcelle,
            parcelle_voisines_without_selected,
          ),
        )
      }
      return this.getParcelleVoisinesWithoutParcellesRemoved(
        parcelles_voisines,
        removed_ref_cadastrale_parcelles,
      )
    },
    getRefsParcellesForDeleted(
      union_parcelle_not_contiguous,
      buffer_current_parcelle,
      buffer_parcelle_voisines_without_selected,
    ) {
      let index_union_overlap_with_current_parcelle = 0
      let removed_ref_cadastrale_parcelles = []
      union_parcelle_not_contiguous.geometry.coordinates.forEach(
        (coordinate, index) => {
          if (this.parcellesIsOverlap(buffer_current_parcelle, coordinate)) {
            index_union_overlap_with_current_parcelle = index
          }
        },
      )

      union_parcelle_not_contiguous.geometry.coordinates.splice(
        index_union_overlap_with_current_parcelle,
        1,
      )

      union_parcelle_not_contiguous.geometry.coordinates.forEach(
        (coordinate) => {
          buffer_parcelle_voisines_without_selected.forEach((parcelle) => {
            if (this.parcellesIsOverlap(parcelle, coordinate)) {
              this.removeSourceAndLayers(
                ...this.concatSourceAndLayersWithRef(
                  ...this.source_and_layers_parcelles_voisines,
                  parcelle.properties.id,
                ),
              )
              removed_ref_cadastrale_parcelles.push(parcelle.properties.id)
            }
          })
        },
      )

      return removed_ref_cadastrale_parcelles
    },
    getParcellesVoisinesWithoutSelected(reference_cadastrale_selected) {
      return this.form.informations.parcelles_voisines.filter(
        (parcelle) => parcelle.properties.id !== reference_cadastrale_selected,
      )
    },
    parcellesIsOverlap(parcelle, coordinate) {
      let intersect_martinez = this.$martinez.intersection(
        parcelle.geometry.coordinates,
        coordinate,
      )
      return this.isExist(intersect_martinez)
        ? intersect_martinez.length > 0
        : false
    },
    setInfosParcellesVoisines(parcelle_selected) {
      if (Array.isArray(parcelle_selected) === false)
        this.form.informations.parcelles_voisines.push(parcelle_selected)
      else this.form.informations.parcelles_voisines = parcelle_selected
    },
  },
  watch: {
    'form.informations.parcelles_voisines': {
      handler(parcelles) {
        if (parcelles.length) {
          this.form.informations.batiments = this.getAllBatis()
        }
      },
    },
  },
  beforeDestroy() {
    if (this.cadastre.is_parcelle_aggregate) {
      this.addListenerClickAggregateParcelle()
    }
  },
}
</script>
