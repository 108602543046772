<template>
  <div class="flex flex-1">
    <slot></slot>
    <portal to="tunnel-progress">
      <div class="flex items-center space-x-3">
        <span class="text-promy-gray-300 font-poppins sm:hidden">
          Progression
        </span>
        <ProgressCycle :value="percentProgress"></ProgressCycle>
      </div>
    </portal>
    <portal to="next-prev-step">
      <div
        class="flex space-x-4 sm:space-x-0 sm:space-y-2 sm:w-full sm:flex-col-reverse lg:py-3 sm:py-2"
      >
        <PromyButton
          class="lg:w-full"
          btnText="C'est parti"
          v-if="currentStep === 0"
          @click="shouldPass(currentStep + 1)"
          :disabled="
            isInvalid || !form.informations.coords_parcelle_principale.length
          "
        />
        <PromyButton
          class="sm:w-full sm:mt-2 precedent-tunnel"
          btnText="Précédent"
          bg_color="grey-400"
          v-if="currentStep > 0"
          @click="shouldPass(currentStep - 1, false)"
        />
        <PromyButton
          class="sm:w-full suivant-tunnel"
          btnText="Suivant"
          v-if="currentStep < total && currentStep !== 0"
          @click="shouldPass(currentStep + 1)"
          :disabled="isInvalid"
        />
        <PromyButton
          class="sm:w-full"
          btnText="Envoyer"
          v-if="currentStep === total"
          @click="sendData"
          :disabled="isInvalid"
        />
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'form-wizard',
  props: {
    form: {
      type: Object,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      tabs: [],
      currentStep: 0,
      totalSteps: 0,
      finish: 1,
    }
  },

  computed: {
    ...mapGetters({
      map: 'initMap/map',
    }),
    total() {
      return this.totalSteps - 3
    },
    percentProgress() {
      return Math.ceil((this.currentStep * 100) / this.total)
    },
    isInvalid() {
      let isInvalid = true
      if (this.tabs.length) {
        isInvalid =
          this.tabs[this.currentStep].$refs.validationObserver.$children[0]
            .$attrs.invalid
      }
      return isInvalid
    },
  },
  created() {
    this.tabs = this.$children
  },

  mounted() {
    this.totalSteps = this.tabs.length
  },
  watch: {
    currentStep: {
      immediate: true,
      handler(current) {
        if (this.$route.path !== this.steps[current].route)
          this.$router.push(this.steps[current].route)
      },
    },
  },
  methods: {
    updateCreateRevelerPotentiel(finish = 0) {
      let form = { ...this.form }
      form.tel = form.prefix + form.tel
      return this.$http
        .post('reveler-potentiel/change', {
          uuid: localStorage.getItem('uuid'),
          finish,
          ...form,
        })
        .then((res) => {
          if (localStorage.getItem('uuid')) return
          localStorage.setItem('uuid', res.data.uuid)
        })
    },
    shouldPass(current, is_next = true) {
      if (!this.form.informations.coords_parcelle_principale.length) {
        this.$toaster.warning('Choisissez une adresse !')
      } else {
        if (current == 6 && !is_next) {
          current--
        }
        if (is_next) {
          this.tabs[this.currentStep].$refs.validationObserver
            .validate()
            .then((is_validate) => {
              if (is_validate) {
                this.activateTab(current)
                this.$emit('setCurrentStep', current)
                this.updateCreateRevelerPotentiel()
              }
            })
        } else {
          this.activateTab(current)
          this.$emit('setCurrentStep', current)
          this.updateCreateRevelerPotentiel()
        }
      }
    },
    activateTab(current) {
      this.currentStep = current
      this.tabs.forEach((tab) => {
        tab.isActive = false
      })
      this.tabs[this.currentStep].isActive = true
    },
    sendData() {
      this.tabs[this.currentStep].$refs.validationObserver
        .validate()
        .then((is_validate) => {
          if (is_validate) {
            this.$emit('is_finished')
            this.updateCreateRevelerPotentiel(this.finish)
              .then((res) => {
                localStorage.removeItem('form_tunnel')
                localStorage.removeItem('uuid')
                // this.$toaster.success("Votre revelation a bien été envoyé.");
                this.$router.push('/fin-tunnel')
              })
              .catch((error) => {
                console.error(error)
              })
          }
        })
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
      this.$store.commit('initMap/MAP', null)
    }
  },
}
</script>

<style lang="scss" scoped>
.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #00a79c; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
</style>
