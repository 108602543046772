<template>
  <div class="flex flex-col">
    <div
      class="max-w-4xl w-full flex flex-col self-center space-y-4 py-6 lg:px-6"
    >
      <h2>Conditions Générales d’Utilisation</h2>
      <div class="intro">
        <p>
          Les présentes Conditions Générales d’Utilisation (CGU) régissent
          l’accès et l’utilisation du site
          <a target="_blank" href="https://www.promy.fr">www.promy.fr</a>
        </p>

        <p>
          L’utilisation du site
          <a target="_blank" href="https://www.promy.fr">www.promy.fr</a>
          implique l’acceptation pleine et entière des conditions générales
          d’utilisation ci-après décrites. Ces conditions d’utilisation sont
          susceptibles d’être modifiées ou complétées à tout moment, les
          utilisateurs du site
          <a target="_blank" href="https://www.promy.fr">www.promy.fr</a> sont
          donc invités à les consulter de manière régulière.
        </p>

        <p>
          Ce site est normalement accessible à tout moment aux utilisateurs. Une
          interruption pour raison de maintenance technique peut être toutefois
          décidée par Promy, qui s’efforcera alors de communiquer préalablement
          aux utilisateurs les dates et heures de l’intervention. Le site
          <a target="_blank" href="https://www.promy.fr">www.promy.fr</a> est
          mis à jour régulièrement par Promy. De la même façon, les mentions
          légales peuvent être modifiées à tout moment : elles s’imposent
          néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent
          possible afin d’en prendre connaissance.
        </p>

        <p>
          Tout utilisateur qui crée un espace personnel chez Promy reconnait
          avoir pris connaissance et accepte les Conditions Générales
          d’Utilisation du site
          <a target="_blank" href="https://www.promy.fr">www.promy.fr</a>
        </p>

        <p>
          Les informations proposées sur le site sont de nature générales et
          sont destinées à présenter les Services fournis par la Société.
        </p>

        <p>
          Seules les présentes CGU et le contrat Promy sont de nature
          contractuelles.
        </p>
      </div>
      <div class="condition">
        <h3>1. ACCÈS AU SITE</h3>
        <div class="content">
          <p>
            Promy se réserve le droit, quelle qu’en soit la raison et à sa seule
            discrétion, de mettre un terme, de modifier, de suspendre ou
            d’interrompre l’accès à tout ou partie du site web, ainsi qu’à ses
            contenus, ses caractéristiques ou de modifier les heures de
            disponibilité sans préavis.
          </p>
          <p>
            Les utilisateurs du site déclarent connaître et comprendre Internet
            et ses limites dont, notamment, ses caractéristiques fonctionnelles
            et performances techniques : les risques d'interruption, les temps
            de réponse pour consulter, interroger ou transférer des
            informations, les risques, quels qu'ils soient, inhérents à toute
            connexion sur le réseau.
          </p>
          <p>
            Promy ne peut être tenue pour responsable d’un quelconque dommage
            direct ou indirect, de quelque nature que ce soit, résultant de
            l’accès ou de la navigation sur le site, en ce compris toute
            détérioration et/ou virus pouvant affecter votre équipement
            informatique.
          </p>
          <p>
            Promy s'efforcera, dans la mesure du possible, de maintenir le site
            accessible 7 jours sur 7, 24 heures sur 24, mais ne pourra être
            tenue à une obligation d'y parvenir.
          </p>
          <p>
            L'accès au site pourra être interrompu, notamment pour des raisons
            de maintenance et de mise à niveau ou pour toute autre raison
            technique sans que cela puisse engager sa responsabilité.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>2. PROPRIÉTÉ INTELLECTUELLE</h3>
        <div class="content">
          <p>
            L'ensemble de ce site relève de la législation française et
            internationale sur le droit d'auteur et la propriété intellectuelle.
          </p>
          <p>
            Tous les droits de reproduction et de représentation sont réservés,
            y compris pour les documents téléchargeables et les représentations
            iconographiques et photographiques. La reproduction de tout ou
            partie de ce site sur tout type de support quel qu'il soit est
            formellement interdite sauf mention contraire et autorisation
            expresse du directeur de la publication. Les marques citées sur ce
            site sont déposées par les sociétés qui en sont propriétaires.
          </p>
          <p>
            Toute reproduction, représentation, diffusion ou rediffusion, totale
            ou partielle, du contenu de ce site par quelque procèdé que ce soit
            sans l'autorisation expresse et préalable de Promy est interdite
            conformément aux dispositions de l’article L.713- 2 du code de la
            propriété́ intellectuelle. De même, toute utilisation des contenus du
            site à des fins illicites peut faire l’objet de poursuites
            judiciaires.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>3. DONNÉES PERSONNELLES</h3>
        <div class="content">
          <p>
            Promy s'engage à respecter la vie privée des utilisateurs et la
            confidentialité de leurs données personnelles lors de leur
            navigation sur le site selon la
            <a target="_blank" href="/politique-de-protection-des-donnees"
              >Politique de protection des données</a
            >. L’utilisateur est invité à prendre connaissance de la Politique
            de protection des données
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>4. SÉCURITÉ DE VOTRE IDENTIFIANT ET DE VOTRE MOT DE PASSE</h3>
        <div class="content">
          <p>
            Tout utilisateur du site
            <a target="_blank" href="https://www.promy.fr">www.promy.fr</a> ,
            détenteur d’un identifiant et d’un mot de passe s’engagent à
            préserver la confidentialité de ses informations.
          </p>
          <p>
            L’identifiant de connexion et le mot de passe sont strictement
            confidentiels et sont réserves à un usage personnel.
          </p>
          <p>
            L’utilisateur s’engage à préserver la confidentialité de son
            identifiant et de son mot de passe et à ne les transmettre, en aucun
            cas, à un tiers.
          </p>
          <p>
            L’utilisateur est responsable de toute activité́ sur le site qui
            découle d’une connexion à partir de son identifiant.
          </p>
          <p>
            Promy ne peut, en aucun cas, être tenu responsable des conséquences
            d’une connexion sur le site, dès lors que l’identifiant et le mot de
            passe de l’utilisateur ont permis la connexion.
          </p>
          <p>
            En cas de perte de son mot de passe, l’Utilisateur dispose d’un lien
            dédié sur la page d’identification et pourra choisir un nouveau mot
            de passe.
          </p>
          <p>
            En cas d’utilisation non autorisée par l’utilisateur de son mot de
            passe, celui-ci à l’obligation de changer son mot de passe via la
            page dédiée du site.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>5. RESPONSABILITÉ DE PROMY</h3>
        <div class="content">
          <p>
            Les informations communiquées sur le site internet «
            <a target="_blank" href="https://www.promy.fr">www.promy.fr</a> »
            sont fournies à titre informatif.
          </p>
          <p>
            La responsabilité de Promy ne saurait être engagée en cas d'erreur
            ou d'omission dans les informations fournies. L’utilisateur
            reconnait utiliser les informations fournies sous sa seule
            responsabilité́.
          </p>
          <p>
            Il est d’autre part précisé que Promy se réserve le droit de
            corriger, à tout moment et sans préavis, le contenu du site
            notamment à des fins d’actualisation.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>6. LIENS</h3>
        <div class="content">
          <p>
            Le site
            <a target="_blank" href="https://www.promy.fr">www.promy.fr</a>
            présente des interactions avec d’autres sites tiers ; notamment, des
            prestataires, des partenaires ou des réseaux sociaux. Promy ne
            saurait être tenu responsable des contenus ou des interactions avec
            ces tiers.
          </p>
        </div>
      </div>
      <div class="condition">
        <h3>7. ASSISTANCE - SERVICE CLIENT</h3>
        <div class="content">
          <p>
            par email à l’adresse suivante :
            <a href="mailto:contact@promy.fr">contact@promy.fr</a> <br />
            par téléphone, du lundi au vendredi, de 09h00 à 18h00 au 04 26 00 89
            70 (appel non surtaxé – coût selon opérateur – horaires en France
            métropolitaine).
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Nos Conditions Générales d'Utilisation | Promy"
  },
}
</script>
<style lang="scss" scoped>
h2 {
  @apply font-poppins text-dark-purple-400 font-bold text-2xl;
}
h3 {
  @apply font-poppins text-dark-purple-400 font-bold text-xl mb-2;
}

a {
  @apply text-blue-ice-400;
  &:hover {
    @apply underline;
  }
}
.intro,
.content p {
  @apply font-poppins;
  color: #333;
}

.content,
.intro {
  @apply flex flex-col space-y-3;
}
</style>
