<template>
  <div class="w-full h-full relative">
    <div
      class="absolute w-full h-full bg-black z-20 opacity-50"
      :class="{
        hidden: !cadastre.is_api_loading && map_is_loaded,
      }"
    ></div>
    <proLoader
      v-if="cadastre.is_api_loading || !map_is_loaded"
      class="absolute z-20 center-element"
      :size_bg_promy="0"
      :color="'#ffffff'"
    ></proLoader>

    <div class="w-full h-full" id="mapbox"></div>
    <div
      v-if="showBtnParcelle"
      class="absolute top-0 left-0 pl-12 pt-10 flex flex-col sm:pl-7 sm:pt-6"
    >
      <div
        class="text-dark-grey-400 font-poppins font-medium text-3xl mb-2 sm:text-xl"
      >
        C’est bien votre parcelle ?
      </div>
      <div class="flex lg:flex-col xl:space-x-3 lg:space-y-3">
        <ChangeParcelle
          :form="form"
          :cadastre="cadastre"
          :map="map"
          class="sm:w-full"
        ></ChangeParcelle>
        <AggregateParcelle
          :form="form"
          :cadastre="cadastre"
          :map="map"
          class="sm:w-full"
        ></AggregateParcelle>
      </div>
    </div>
    <div
      class="absolute z-20 center-element bg-white p-4 rounded-lg"
      v-if="cadastre.error_parcelle_not_found"
    >
      {{ cadastre.error_parcelle_not_found }}
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import sourcesAndLayers from '@/mixins/sourcesAndLayers'
import { mapGetters } from 'vuex'
import ChangeParcelle from './actionMap/ChangeParcelle'
import AggregateParcelle from './actionMap/AggregateParcelle'
import parcelle from '@/mixins/parcelle'
import helpers from '@/mixins/helpers'

export default {
  data() {
    return {
      map_is_loaded: false,
    }
  },
  components: {
    ChangeParcelle,
    AggregateParcelle,
  },
  mixins: [Mapbox, sourcesAndLayers, parcelle, helpers],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    showBtnParcelle: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },

  mounted() {
    this.map_is_loaded = false
    if (!this.map) {
      this.$store.commit('initMap/MAP', this.createMap([4.85, 45.75]))
      this.map.on('load', () => {
        if (!this.cadastre.is_api_loading) {
          this.loadDataToMap()
        }
        if (window.screen.width <= 1024) {
          this.map.on('moveend', () => {
            this.map_is_loaded = true
            this.map.off('moveend')
          })
        } else this.map_is_loaded = true
      })
    } else {
      this.map_is_loaded = true
      document.querySelector('#mapbox').replaceWith(this.map.getContainer())
    }
  },
  methods: {
    loadDataToMap() {
      if (this.cadastre.old_adresse !== this.form.informations.adresse) {
        this.removeLayers()

        let features = this.addRefCadastaleToPropertiesFeaturesAutour(
          this.cadastre.data.geospatial_autour.features,
          this.cadastre.data.geospatial.features.parcelle,
        )
        this.cadastre.features_autour = features.features_autour

        if (!this.form.informations.current_parcelle) {
          this.form.informations.current_parcelle =
            this.cadastre.data.geospatial.features.parcelle
          this.form.informations.current_parcelle.id =
            features.index_parcelle_principale
        }

        this.cadastre.parcelle_autour = features.features_autour.map(
          (feature) => feature.parcelle,
        )
        this.addParcellesAutour(this.cadastre.parcelle_autour)
        if (this.form.informations.parcelles_voisines.length) {
          this.initParcellesVoisines(this.form.informations.parcelles_voisines)
        }
        this.addPolygonsToMap(
          this.form.informations.current_parcelle,
          this.first_source_layers_parcelle,
        )
        this.fitBoundsGeojsonInMap(this.form.informations.current_parcelle, 150)
      }

      this.cadastre.old_adresse = this.form.informations.adresse
    },
  },
  watch: {
    'cadastre.is_api_loading': {
      deep: true,
      immediate: true,
      handler(is_loading) {
        if (!is_loading && this.map) {
          this.loadDataToMap()
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      map: 'initMap/map',
    }),
  },
}
</script>
<style lang="scss" scoped>
#mapbox {
  @screen lg {
    height: 70vh;
  }
}
.center-element {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
