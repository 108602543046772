export default {
  data() {
    return {
      first_source_layers_parcelle: [
        'promy-parcelle',
        'promy-background-parcelle',
        'promy-layer-outline-parcelle',
      ],
      source_layers_selected_parcelles_autour: [
        'promy-parcelles_autour',
        'promy-background-parcelle-autour',
        'promy-parcelles_autour',
      ],
      source_and_layers_parcelles_voisines: [
        'promy-selected-voisines',
        'promy-background-selected-voisines',
        'promy-outline-selected-voisines',
      ],
      source_layers_selected_parcelle: [
        'promy-selected-parcelle',
        'promy-background-selected-parcelle',
        'promy-outline-selected-parcelle',
      ],
      init_source_and_layers_parcelles_voisines: [
        'promy-selected-voisines',
        'promy-background-selected-voisines',
        'promy-outline-selected-voisines',
      ],
      source_and_layers_parcelles_aggregate: [
        'promy-selected-aggregate',
        'promy-background-selected-aggregate',
        'promy-outline-selected-aggregate',
      ],
      source_layers_batiments: [
        'promy-batiments',
        'promy-background_batiments',
        'promy-layer-outline-bati',
      ],
      source_layers_all_parcelle: [
        'promy-all_parcelle',
        'promy-background-all-parcelle',
        'promy-layer-outline-all-parcelle',
      ],
    }
  },
}
