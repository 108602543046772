<template>
  <div class="fixed z-30 bottom-0 right-0 mr-6 mb-6">
    <button
      id="backToTop"
      @click="backToTop"
      class="w-11 h-11 flex justify-center items-center rounded-full bg-promy-purple-300"
    >
      <img src="/images/backToTop.svg" alt="" />
    </button>
  </div>
</template>
<script>
export default {
  mounted() {
    window.onscroll = function () {
      var pageOffset =
        document.documentElement.scrollTop || document.body.scrollTop
      if (pageOffset > 180) {
        document.getElementById('backToTop').style.visibility = 'visible'
      } else {
        document.getElementById('backToTop').style.visibility = 'hidden'
      }
    }
  },
  methods: {
    backToTop() {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
  },
}
</script>
