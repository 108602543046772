<template>
  <LayoutIcon :fill="fill">
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M0.782241 12.7472H0.796679C1.01914 12.7474 1.20163 12.5711 1.20921 12.3487C1.43239 5.97323 6.78164 0.985827 13.1571 1.20896C15.6094 1.29481 17.9706 2.15933 19.8986 3.67729H17.7031C17.4752 3.67729 17.2905 3.862 17.2905 4.08982C17.2905 4.31764 17.4752 4.50235 17.7031 4.50235H21.0033H21.0091C21.0414 4.49972 21.0732 4.49338 21.104 4.48338C21.1251 4.48033 21.1458 4.47579 21.1663 4.46976C21.1778 4.46481 21.1865 4.4545 21.1976 4.44831C21.2346 4.43021 21.2686 4.40639 21.2983 4.37777L21.3024 4.37488C21.3129 4.36054 21.3224 4.34554 21.3309 4.32991C21.3525 4.30361 21.3707 4.27463 21.3849 4.24369C21.3963 4.2108 21.4032 4.17655 21.4055 4.1418C21.4103 4.12478 21.4137 4.1074 21.4158 4.08982V0.789565C21.4158 0.561745 21.2311 0.377034 21.0033 0.377034C20.7755 0.377034 20.5908 0.561745 20.5908 0.789565V3.17895C15.3052 -1.15171 7.50955 -0.37759 3.1789 4.90808C1.46003 7.00601 0.478102 9.61058 0.384147 12.3211C0.376516 12.5486 0.554678 12.7394 0.782241 12.7472Z"
        />
        <path
          d="M4.12207 20.4298C3.97067 20.2589 3.70944 20.2431 3.53854 20.3945C3.36765 20.5459 3.35187 20.8071 3.50327 20.978C3.78725 21.2967 4.08726 21.6007 4.40218 21.8889C4.57343 22.0392 4.83405 22.0222 4.98436 21.851C5.1304 21.6846 5.11906 21.4326 4.95868 21.28C4.66548 21.0111 4.38624 20.7273 4.12207 20.4298Z"
        />
        <path
          d="M9.04525 23.6963C8.66809 23.5687 8.29785 23.4214 7.93595 23.2554C7.7318 23.1542 7.48428 23.2378 7.38316 23.4419C7.28204 23.6461 7.36558 23.8936 7.56973 23.9947C7.57674 23.9982 7.58386 24.0015 7.59108 24.0045C7.97906 24.1831 8.37623 24.3412 8.78082 24.4781C8.99668 24.5511 9.23089 24.4353 9.30391 24.2194C9.37698 24.0036 9.26116 23.7694 9.04525 23.6963Z"
        />
        <path
          d="M6.87817 22.7008V22.7004C6.5353 22.4973 6.20296 22.277 5.88232 22.0403C5.70343 21.8992 5.44405 21.9298 5.30291 22.1087C5.16178 22.2876 5.19241 22.547 5.37129 22.6881C5.37799 22.6934 5.3848 22.6985 5.39181 22.7033C5.73473 22.9572 6.09039 23.1933 6.45739 23.4108C6.65344 23.527 6.90658 23.4623 7.02276 23.2662C7.13899 23.0701 7.07423 22.817 6.87817 22.7008Z"
        />
        <path
          d="M3.375 19.4986C3.14244 19.1753 2.92658 18.8403 2.72815 18.4949C2.61424 18.2973 2.36167 18.2294 2.16401 18.3433C1.96636 18.4573 1.8985 18.7098 2.01241 18.9075C2.22497 19.2767 2.45629 19.6349 2.70546 19.9805C2.84227 20.1627 3.10087 20.1994 3.28306 20.0626C3.46003 19.9297 3.50062 19.6809 3.375 19.4986Z"
        />
        <path
          d="M2.22436 17.6001C2.22436 17.5426 2.21229 17.4858 2.18904 17.4332L2.18821 17.4303C2.02676 17.0667 1.88423 16.6949 1.76124 16.3165C1.70142 16.0967 1.47469 15.9669 1.25486 16.0268C1.03503 16.0866 0.905288 16.3134 0.965157 16.5332C0.968664 16.546 0.972737 16.5586 0.97743 16.571C1.10908 16.9774 1.26166 17.3768 1.43452 17.7674C1.50068 17.9167 1.64867 18.0129 1.81198 18.0128C2.0398 18.0127 2.22441 17.828 2.22436 17.6001Z"
        />
        <path
          d="M1.13512 15.6514C1.13517 15.6514 1.13527 15.6513 1.13532 15.6513C1.35794 15.6039 1.50005 15.3851 1.45297 15.1625C1.37078 14.7728 1.30854 14.3791 1.26651 13.9831C1.24248 13.7564 1.0392 13.5921 0.812518 13.6161C0.585832 13.6401 0.421541 13.8434 0.445571 14.0701C0.490743 14.4943 0.557625 14.9159 0.646061 15.3333C0.693296 15.5562 0.912247 15.6986 1.13512 15.6514Z"
        />
        <path
          d="M24.4691 15.2301C24.4689 15.23 24.4688 15.23 24.4686 15.23C24.2475 15.1752 24.0239 15.31 23.969 15.5311C23.8734 15.9179 23.7579 16.2994 23.6229 16.6742C23.5457 16.8886 23.6569 17.125 23.8713 17.2023C24.0857 17.2795 24.3221 17.1683 24.3993 16.9539C24.5441 16.5525 24.668 16.1438 24.7706 15.7295C24.8253 15.5084 24.6903 15.2848 24.4691 15.2301Z"
        />
        <path
          d="M11.3711 24.2217C10.9757 24.1746 10.583 24.1071 10.1945 24.0195C9.97229 23.9693 9.75138 24.1087 9.70116 24.331C9.65093 24.5532 9.79037 24.7741 10.0126 24.8244C10.429 24.9182 10.8499 24.9905 11.2737 25.041C11.2902 25.043 11.3067 25.0439 11.3232 25.0438C11.5511 25.0434 11.7354 24.8582 11.7349 24.6304C11.7345 24.4218 11.5783 24.2463 11.3711 24.2217Z"
        />
        <path
          d="M23.7084 17.5836C23.5033 17.4846 23.2568 17.5705 23.1576 17.7754C22.9842 18.134 22.7922 18.4834 22.5825 18.822C22.4659 19.0178 22.53 19.271 22.7258 19.3876C22.9167 19.5014 23.1636 19.4433 23.2838 19.2564C23.5083 18.8934 23.7139 18.519 23.8997 18.1348C23.9991 17.9297 23.9134 17.683 23.7084 17.5836Z"
        />
        <path
          d="M22.4895 19.7288C22.3112 19.587 22.0517 19.6166 21.9099 19.795C21.9074 19.7981 21.905 19.8012 21.9026 19.8044V19.8035C21.6592 20.1193 21.3997 20.4222 21.125 20.7111C20.9679 20.8763 20.9745 21.1376 21.1396 21.2946C21.3048 21.4517 21.5661 21.4452 21.7232 21.28C22.0169 20.9704 22.2948 20.6462 22.5557 20.3085C22.6975 20.1302 22.6679 19.8706 22.4895 19.7288Z"
        />
        <path
          d="M24.7232 12.7725C24.499 12.7611 24.308 12.9335 24.2966 13.1576C24.2964 13.1618 24.2963 13.166 24.2962 13.1702C24.2822 13.5683 24.2476 13.9654 24.1926 14.3599C24.1615 14.5853 24.3188 14.7933 24.5441 14.8249C24.5632 14.8276 24.5825 14.829 24.6019 14.829C24.8075 14.8287 24.9815 14.677 25.0099 14.4734C25.0686 14.0508 25.1056 13.6255 25.1208 13.1991C25.1287 12.9715 24.9508 12.7806 24.7232 12.7725Z"
        />
        <path
          d="M20.2608 21.5317V21.5329C19.9571 21.7921 19.6405 22.0355 19.3119 22.2623C19.1241 22.3921 19.0771 22.6497 19.2069 22.8375C19.3368 23.0254 19.5944 23.0724 19.7822 22.9425C20.1335 22.6986 20.4722 22.437 20.797 22.1587C20.9702 22.0106 20.9905 21.7502 20.8424 21.577C20.6944 21.4039 20.4339 21.3836 20.2608 21.5317Z"
        />
        <path
          d="M16.0988 23.8122C15.7175 23.9271 15.3306 24.0221 14.9396 24.0968C14.7158 24.1399 14.5693 24.3561 14.6124 24.5799C14.6497 24.7738 14.8192 24.9141 15.0167 24.9145C15.0428 24.9146 15.0689 24.9122 15.0947 24.9075C15.5139 24.8273 15.9287 24.7253 16.3372 24.6018C16.5536 24.5305 16.6712 24.2972 16.5999 24.0808C16.5311 23.8721 16.3107 23.754 16.0988 23.8122Z"
        />
        <path
          d="M13.7779 24.259C13.7703 24.2594 13.7628 24.2601 13.7552 24.2609L13.7573 24.2626C13.4273 24.2906 13.0894 24.3038 12.7528 24.3038H12.5642C12.3393 24.307 12.1554 24.4842 12.1439 24.7089C12.1397 24.9367 12.3211 25.1247 12.5488 25.1289C12.5489 25.1289 12.5489 25.1289 12.549 25.1289H12.7528C13.1133 25.1289 13.4747 25.1128 13.8254 25.0827C14.0528 25.0696 14.2266 24.8745 14.2134 24.6471C14.2003 24.4196 14.0053 24.2459 13.7779 24.259Z"
        />
        <path
          d="M18.2967 22.8882H18.2971C17.9479 23.0795 17.5891 23.2527 17.2221 23.4072C17.011 23.4928 16.9092 23.7334 16.9948 23.9445C17.0804 24.1556 17.321 24.2574 17.5321 24.1718C17.5352 24.1705 17.5383 24.1692 17.5413 24.1679C17.9346 24.0024 18.319 23.8168 18.6931 23.6118C18.8929 23.5023 18.9662 23.2516 18.8567 23.0518C18.7473 22.852 18.4965 22.7787 18.2967 22.8882Z"
        />
        <path
          d="M12.7526 4.08984C7.9681 4.08984 4.08948 7.96847 4.08948 12.753C4.08948 17.5376 7.9681 21.4162 12.7526 21.4162C17.5372 21.4162 21.4158 17.5376 21.4158 12.753C21.4104 7.97074 17.5349 4.09531 12.7526 4.08984ZM19.3532 13.1655H20.5697C20.3554 17.1628 17.1625 20.3558 13.1652 20.5701V19.3535C13.1652 19.1257 12.9805 18.941 12.7526 18.941C12.5248 18.941 12.3401 19.1257 12.3401 19.3535V20.5701C8.34283 20.3558 5.14989 17.1628 4.93558 13.1655H6.15214C6.37996 13.1655 6.56467 12.9808 6.56467 12.753C6.56467 12.5252 6.37996 12.3405 6.15214 12.3405H4.93558C5.14989 8.3432 8.34283 5.15026 12.3401 4.93595V6.1525C12.3401 6.38032 12.5248 6.56503 12.7526 6.56503C12.9805 6.56503 13.1652 6.38032 13.1652 6.1525V4.93595C17.1625 5.15026 20.3554 8.3432 20.5697 12.3405H19.3532C19.1253 12.3405 18.9406 12.5252 18.9406 12.753C18.9406 12.9808 19.1253 13.1655 19.3532 13.1655Z"
        />
        <path
          d="M13.1652 11.5914V7.80267C13.1652 7.57485 12.9805 7.39014 12.7527 7.39014C12.5249 7.39014 12.3402 7.57485 12.3402 7.80267V11.5914C11.9908 11.7161 11.7158 11.9912 11.591 12.3405H9.45245C9.22463 12.3405 9.03992 12.5252 9.03992 12.753C9.03992 12.9809 9.22463 13.1656 9.45245 13.1656H11.591C11.8171 13.8089 12.5219 14.1472 13.1652 13.9211C13.8086 13.6951 14.1469 12.9903 13.9208 12.3469C13.7966 11.9935 13.5187 11.7156 13.1652 11.5914ZM12.7527 13.1656C12.5249 13.1656 12.3402 12.9809 12.3402 12.753C12.3402 12.5252 12.5249 12.3405 12.7527 12.3405C12.9805 12.3405 13.1652 12.5252 13.1652 12.753C13.1652 12.9809 12.9806 13.1656 12.7527 13.1656Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24.7525"
            height="24.7525"
            fill="white"
            transform="translate(0.376221 0.376465)"
          />
        </clipPath>
      </defs>
    </svg>
  </LayoutIcon>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'purple',
    },
  },
}
</script>
