<template>
  <button
    @click="
      () => {
        $emit('click')
      }
    "
    class="btn"
    :class="
      !disabled
        ? `text-${color} bg-${bg_color} hover:bg-${bg_hover}`
        : disabled_classes
    "
  >
    {{ btnText }}
  </button>
</template>
<script>
export default {
  props: {
    disabled: {
      default: false,
    },
    disabled_classes: {
      type: String,
      default: 'text-grey-400 bg-promy-gray-100',
    },
    btnText: {
      type: String,
    },
    color: {
      type: String,
      default: 'white',
    },
    bg_color: {
      type: String,
      default: 'blue-ice-400',
    },
    bg_hover: {
      type: String,
      default: 'blue-ice-500',
    },
  },
}
</script>
<style scoped>
.btn {
  @apply px-8 py-3 font-bold font-poppins text-base rounded-lg;
}
</style>
