function page(path) {
  return () => import(/* webpackChunkName: '' */ `../pages/${path}`)
}
export default [
  {
    path: '/',
    name: 'LandingPage',
    component: page('LandingPage/'),
  },
  {
    path: '/tunnel',
    name: 'tunnel',
    alias: [
      '/adresse',
      '/residence',
      '/superficies',
      '/profile',
      '/vendre-bien',
      '/informations-complementaire',
      '/cherche-offres',
      '/informations-personnelles',
    ],
    component: page('Tunnel/'),
  },
  {
    path: '/Contactez-nous',
    name: 'ContactezNous',
    component: page('ContactezNous'),
  },
  {
    path: '/fin-tunnel',
    name: 'FinTunnel',
    component: page('Tunnel/Steps/finishedTunnel'),
  },
  {
    path: '/conditions-generales-d-utilisation',
    name: 'CGU',
    component: page('CGU'),
  },
  {
    path: '/politique-de-protection-des-donnees',
    name: 'PolitiqueProtection',
    component: page('PolitiqueProtection'),
  },
  {
    path: '/mentions-legales',
    name: 'MentionsLegales',
    component: page('MentionsLegales'),
  },
  {
    path: '*',
    component: page('pagesErreur/pageNotFound'),
  },
]
