<template>
  <div
    class="flex w-full flex-1 justify-center items-center"
    style="padding-top: 20px; padding-bottom: 20px"
  >
    <div class="flex flex-1 w-full flex-col space-y-8" style="max-width: 620px">
      <img
        id="img-fin-tunnel"
        src="/images/findOffre-pin.svg"
        alt=""
        srcset=""
        style="margin: 0 auto"
        class="h-auto pt-10 w-3/4"
      />
      <p
        id="text-felicitations"
        class="text-promy-purple-900 font-extrabold text-center px-8"
        style="min-height: 72px"
      ></p>

      <p
        id="text-meta-felicitations"
        class="text-promy-gray-600 m-0 text-center px-8"
        style="min-height: 82px; margin-top: 20px"
      ></p>

      <PromyButton
        class="sm:text-xs w-fit mx-auto"
        btnText="Retour à l'accueil"
        @click="$router.push('/')"
      />
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    var vSlide = this.$gsap.timeline({
      paused: true,
      repeat: 0,
    })
    vSlide.from('#img-fin-tunnel', { duration: 2, opacity: 0 }).to(
      '#text-felicitations',
      {
        text: 'Merci de nous faire confiance !</br>Vos coordonnées ont été transmises à notre équipe et nous ne manquerons pas de vous recontacter sous peu !',
        ease: 'power1.in',
        duration: 4,
        repeat: 0,
        yoyo: true,
        repeatDelay: 0.4,
      },
      '-=1.5',
    )
    vSlide.from('#img-fin-tunnel', { duration: 1 }).to(
      '#text-meta-felicitations',
      {
        text: 'Pour en savoir plus ou pour toute autre demande, rendez-vous sur Promy.fr.</br> A très bientôt 😊',
        ease: 'power1.in',
        duration: 4,
        repeat: 0,
        yoyo: true,
        repeatDelay: 0.4,
      },
      '-=1.5',
    )
    vSlide.play()
  },
}
</script>
