<template>
  <LayoutIcon :fill="fill">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.6707 11.425L20.3373 0.0916466C20.134 -0.0270253 19.884 -0.0310097 19.678 0.0829747L0.344688 10.7497C0.132031 10.8663 0 11.0903 0 11.333V39.333C0 39.7016 0.298672 39.9996 0.666641 39.9996H4C4.36797 39.9996 4.66664 39.7016 4.66664 39.333V15.9997H35.3333V39.3331C35.3333 39.7017 35.6313 39.9997 35.9999 39.9997H39.3333C39.702 39.9997 39.9999 39.7017 39.9999 39.3331V11.9997C40 11.763 39.8747 11.5443 39.6707 11.425ZM38.6666 38.6663H36.6666V15.333C36.6666 14.965 36.3687 14.6663 36 14.6663H4C3.63203 14.6663 3.33336 14.965 3.33336 15.333V38.6663H1.33336V11.7263L19.99 1.43297L38.6666 12.3816V38.6663Z"
      />
      <path
        d="M33.3335 30.6665H25.3335C24.9648 30.6665 24.6669 30.9645 24.6669 31.3331V39.3331C24.6669 39.7018 24.9648 39.9998 25.3335 39.9998H33.3335C33.7022 39.9998 34.0002 39.7018 34.0002 39.3331V31.3331C34.0002 30.9645 33.7022 30.6665 33.3335 30.6665ZM32.6668 38.6665H26.0001V31.9999H32.6668V38.6665Z"
      />
      <path
        d="M30 31.333V33.333H28.6666V31.333H27.3333V33.9996C27.3333 34.3683 27.6312 34.6663 27.9999 34.6663H30.6665C31.0352 34.6663 31.3332 34.3683 31.3332 33.9996V31.333H30Z"
      />
      <path
        d="M25.3334 30.6665H17.3334C16.9654 30.6665 16.6667 30.9645 16.6667 31.3331V39.3331C16.6667 39.7018 16.9654 39.9998 17.3334 39.9998H25.3334C25.702 39.9998 26 39.7018 26 39.3331V31.3331C26 30.9645 25.702 30.6665 25.3334 30.6665ZM24.6667 38.6665H18V31.9999H24.6667V38.6665Z"
      />
      <path
        d="M22 31.333V33.333H20.6666V31.333H19.3333V33.9996C19.3333 34.3683 19.6319 34.6663 19.9999 34.6663H22.6665C23.0352 34.6663 23.3332 34.3683 23.3332 33.9996V31.333H22Z"
      />
      <path
        d="M33.3335 22.6665H25.3335C24.9648 22.6665 24.6669 22.9645 24.6669 23.3331V31.3331C24.6669 31.7018 24.9648 31.9998 25.3335 31.9998H33.3335C33.7022 31.9998 34.0002 31.7018 34.0002 31.3331V23.3331C34.0002 22.9645 33.7022 22.6665 33.3335 22.6665ZM32.6668 30.6665H26.0001V23.9999H32.6668V30.6665Z"
      />
      <path
        d="M30 23.333V25.333H28.6666V23.333H27.3333V25.9996C27.3333 26.3683 27.6312 26.6663 27.9999 26.6663H30.6665C31.0352 26.6663 31.3332 26.3683 31.3332 25.9996V23.333H30Z"
      />
      <path d="M36 20H4V21.3333H36V20Z" />
      <path d="M36 17.333H4V18.6664H36V17.333Z" />
      <path
        d="M23.3333 9.33301H16.6666C16.2987 9.33301 16 9.63168 16 9.99965V12.6663C16 13.0343 16.2987 13.3329 16.6666 13.3329H23.3333C23.702 13.3329 23.9999 13.0343 23.9999 12.6663V9.99965C23.9999 9.63168 23.702 9.33301 23.3333 9.33301ZM22.6666 11.9997H17.3332V10.6664H22.6666V11.9997Z"
      />
    </svg>
  </LayoutIcon>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: 'orange',
    },
  },
}
</script>
