export default {
  methods: {
    getIntersectBatimentsWithMultiParcelle(
      parcelles,
      batiments,
      current_parcelle = null,
    ) {
      let intersect_batiments = []
      let vm = this
      batiments.forEach(function (batiment) {
        let intersect_martinez = vm.$martinez.intersection(
          parcelles.geometry.coordinates,
          batiment.geometry.coordinates,
        )
        if (intersect_martinez) {
          intersect_martinez =
            vm.getCoordinates(batiment.geometry.coordinates).length > 1
              ? [intersect_martinez]
              : intersect_martinez

          intersect_martinez.forEach((poly) => {
            let poly_bati = vm.$turf.polygon(vm.getCoordinates(poly))
            if (vm.getSurfaceMesurer(poly_bati) > 0) {
              if (current_parcelle) {
                vm.linkedBatimentWithTheirPacelle(
                  poly_bati,
                  current_parcelle,
                  intersect_batiments,
                )
              } else intersect_batiments.push(poly_bati)
            }
          })
        }
      })
      return intersect_batiments
    },
    linkedBatimentWithTheirPacelle(
      poly_bati,
      current_parcelle,
      intersect_batiments,
    ) {
      poly_bati.properties.parcelle_id = []
      poly_bati.properties.parcelle_id.push(
        this.getRefParcelle(current_parcelle),
      )
      intersect_batiments.push(poly_bati)
    },
    getAllBatimentsWithUniqueId(all_features, all_parcelles) {
      let vm = this
      let batiments = []
      let union_parcelles = this.$turf.union(...all_parcelles)
      all_features.forEach(function (feature) {
        let batis = vm.getIntersectBatimentsWithMultiParcelle(
          union_parcelles,
          feature.batiments,
          feature.parcelle,
        )
        batiments.push(...batis)
      })
      batiments = this.removeDuplicateBatiments(batiments)
      return batiments
    },
    removeDuplicateBatiments(batiments) {
      let vm = this
      let remove_index_batis = []
      batiments.forEach((batiment, index) => {
        batiments.forEach((temp_batiment, child_index) => {
          if (
            _.isEqual(
              batiment.geometry.coordinates,
              temp_batiment.geometry.coordinates,
            ) &&
            index !== child_index &&
            remove_index_batis.indexOf(index) === -1
          ) {
            if (vm.isExist(batiments[index].properties.parcelle_id)) {
              batiments[index].properties.parcelle_id.push(
                ...temp_batiment.properties.parcelle_id,
              )
            }

            remove_index_batis.push(child_index)
          }
        })
      })
      batiments = batiments.filter(function (bati, index) {
        return remove_index_batis.indexOf(index) === -1
      })
      return batiments
    },
    getMultiPolygon(coordinates) {
      return this.$turf.multiPolygon([this.getCoordinates(coordinates)])
    },
    surfaceSolBati(batiment) {
      var multiPolygon = this.getMultiPolygon(batiment.geometry.coordinates)
      return this.$turf.area(multiPolygon)
    },
    getAllBatis() {
      let all_parcelles = [
        this.form.informations.current_parcelle,
        ...this.form.informations.parcelles_voisines,
      ]
      this.form.informations.superficie_totale =
        this.getSurfaceMultiGeojson(all_parcelles)
      this.form.informations.refsCadastrale = all_parcelles.map((parcelle) =>
        this.getRefParcelle(parcelle),
      )
      try {
        let features_exist = this.getFeaturesExist(
          all_parcelles,
          this.cadastre.features_autour,
        )
        let batis = this.getAllBatimentsWithUniqueId(
          features_exist,
          all_parcelles,
        )

        this.form.informations.superficie_construite =
          this.getSurfaceMultiGeojson(batis)

        return batis
      } catch {
        return []
      }
    },
  },
}
