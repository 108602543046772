<template>
  <section class="pb-16 pt-40 md:pt-20">
    <div class="w-3/4 m-auto max-w-7xl">
      <div
        class="flex sm:flex-col justify-between sm:items-start items-center w-full"
      >
        <img
          src="/images/logo-promy-purple.svg"
          class="md:mb-12 w-[5.5rem] h-auto hidden sm:flex"
        />
        <img
          src="/images/logo-promy-white.svg"
          class="md:mb-12 w-[5.5rem] h-auto  sm:hidden"
        />
        <div
          class="flex sm:flex-col space-x-6 sm:space-x-0 sm:space-y-8 font-poppins font-medium text-base"
          :class="textColor"
        >
          <div class="space-y-2">
            <p @click="$router.push('/Contactez-nous')" class="cursor-pointer">
              Contactez-nous
            </p>
            <p
              @click="$router.push('/conditions-generales-d-utilisation')"
              class="cursor-pointer"
            >
              CGU
            </p>

            <p
              @click="$router.push('/politique-de-protection-des-donnees')"
              class="cursor-pointer"
            >
              Protection des données
            </p>
            <p
              @click="$router.push('/mentions-legales')"
              class="cursor-pointer"
            >
              Mentions légales
            </p>
          </div>
        </div>
      </div>
      <hr class="w-full border-t my-6 border-white" />
      <p class="font-poppins font-medium text-base" :class="textColor">
        Tout droit résérvé © - Promy 2021
      </p>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    textColor: {
      type: String,
      default: 'text-dark-grey-400',
    },
  },
}
</script>
